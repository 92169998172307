import axios from "axios";
import { API_HOST } from "../constants/ServiceConstant";
import {errorHandler,userToken} from "../util/util"

const studentRegister = async (registerFormData) => {
  await userToken();
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_HOST}/student/users/register`, registerFormData)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const studentLogin = async (loginFormData) => {
  await userToken();
  // console.log({axios});
  // console.log({formData});
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_HOST}/student/users/login`, loginFormData)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const studentLogout = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_HOST}/student/users/logout`)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const studentPwdReset = async () => {
   await userToken();
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_HOST}/student/users/password_reset`)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const studentPwdResetConfirmRequest = async (id,hash) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_HOST}/student/users/confirm_reset/${id}/${hash}`)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const studentPwdResetConfirm = async (id,hash,data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_HOST}/student/users/confirm_reset/${id}/${hash}`,data)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const authService = {
  studentRegister,
  studentLogin,
  studentLogout,
  studentPwdReset,
  studentPwdResetConfirmRequest,
  studentPwdResetConfirm
};

export default authService;
