import React from 'react'

const IconLogin = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1621_243)">
                <path d="M20.3897 26.3395C20.0034 26.7279 19.7869 27.2535 19.7876 27.8013C19.7884 28.349 20.0063 28.8741 20.3936 29.2615C20.7809 29.6488 21.306 29.8667 21.8538 29.8674C22.4015 29.8682 22.9272 29.6517 23.3156 29.2654L31.1182 21.4628C31.5062 21.0748 31.7242 20.5486 31.7242 19.9999C31.7242 19.4512 31.5062 18.9249 31.1182 18.5369L23.3156 10.7344C22.9276 10.3464 22.4014 10.1284 21.8526 10.1284C21.3039 10.1284 20.7777 10.3464 20.3897 10.7344C20.0017 11.1224 19.7837 11.6487 19.7837 12.1974C19.7837 12.7461 20.0017 13.2723 20.3897 13.6603L24.6603 17.9309H2.06897C1.52024 17.9309 0.993992 18.1489 0.605986 18.5369C0.21798 18.9249 0 19.4512 0 19.9999C0 20.5486 0.21798 21.0749 0.605986 21.4629C0.993992 21.8509 1.52024 22.0689 2.06897 22.0689H24.6603L20.3897 26.3395Z" fill="currentcolor" />
                <path d="M36.5517 0H17.2414C16.3268 0 15.4498 0.363299 14.8031 1.00998C14.1564 1.65665 13.7931 2.53374 13.7931 3.44828V6.2069C13.7931 6.75562 14.0111 7.28187 14.3991 7.66988C14.7871 8.05788 15.3133 8.27586 15.8621 8.27586C16.4108 8.27586 16.937 8.05788 17.3251 7.66988C17.7131 7.28187 17.931 6.75562 17.931 6.2069V4.13793H35.8621V35.8621H17.931V33.7931C17.931 33.2444 17.7131 32.7181 17.3251 32.3301C16.937 31.9421 16.4108 31.7241 15.8621 31.7241C15.3133 31.7241 14.7871 31.9421 14.3991 32.3301C14.0111 32.7181 13.7931 33.2444 13.7931 33.7931V36.5517C13.7931 37.4663 14.1564 38.3433 14.8031 38.99C15.4498 39.6367 16.3268 40 17.2414 40H36.5517C37.4663 40 38.3433 39.6367 38.99 38.99C39.6367 38.3433 40 37.4663 40 36.5517V3.44828C40 2.53374 39.6367 1.65665 38.99 1.00998C38.3433 0.363299 37.4663 0 36.5517 0Z" fill="currentcolor" />
            </g>
            <defs>
                <clipPath id="clip0_1621_243">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconLogin