import React from 'react'

const IconCart = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <path d="M18.2869 34.4813C18.2869 36.2786 16.7553 37.7295 14.8581 37.7295C12.9608 37.7295 11.4293 36.2786 11.4293 34.4813C11.4293 32.684 12.9608 31.2332 14.8581 31.2332C16.7553 31.2332 18.2869 32.684 18.2869 34.4813ZM29.7161 31.2332C27.8189 31.2332 26.2874 32.684 26.2874 34.4813C26.2874 36.2786 27.8189 37.7295 29.7161 37.7295C31.6134 37.7295 33.1449 36.2786 33.1449 34.4813C33.1449 32.684 31.6134 31.2332 29.7161 31.2332ZM39.9568 10.5101L35.3851 27.8335C35.2022 28.5481 34.5164 29.0678 33.7164 29.0678H10.8578C10.0349 29.0678 9.3263 28.5048 9.16629 27.7252L4.84602 5.24813H1.71439C0.777191 5.24813 0 4.51189 0 3.62407C0 2.73624 0.777191 2 1.71439 2H6.28611C7.10901 2 7.81763 2.56301 7.97764 3.34256L8.96056 8.49627H38.2881C38.8138 8.49627 39.3167 8.73446 39.6368 9.12424C39.9568 9.51401 40.0711 10.0337 39.9568 10.5101ZM36.0937 11.7444H9.57774L12.2751 25.8196H32.3677L36.0937 11.7444Z" fill="currentcolor" />
        </svg>
    )
}

export default IconCart