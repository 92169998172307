import React, { useEffect, useState } from 'react';
import DashHeader from "../dashboard/DashHeader";
import DashboardSidebar from './DashboardSidebar';

const DashMaster = ({ children }) => {
    const [toogle, setToogle] = useState(false);
    const [widthSize, setWidthSize] = useState(0);

    let resizeWindow = () => {
        setWidthSize(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    useEffect(() => {
        setToogle(false);
    }, []);

    return (
        <div className='user-dashboard-layout min-h-screen bg-body-background'>
            <DashHeader setWidthSize={setWidthSize} widthSize={widthSize} toogle={toogle} setToogle={setToogle} />
            <DashboardSidebar setWidthSize={setWidthSize} widthSize={widthSize} setToogle={setToogle} toogle={toogle} />
            <main className={`transition-all duration-[400ms] ms-0 ${toogle && widthSize >= 1200 ? "xl:ms-[100px]" : "xl:ms-[280px]"}`}>
                <div className="px-4 sm:px-6 xl:px-12 pt-[76px] lg:pt-[100px] xxl:px-16  max-w-[1804px] mx-auto">
                    {children}
                </div>
            </main>
        </div>
    );
};

export default DashMaster;
