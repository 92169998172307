import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../services/user.service";
import { userToken } from "../util/util";
import CartService from "../services/cart.sevice";

const initialState = {
  cart: {},
};

export const cartData = createAsyncThunk(
  "auth/student-cart/list",
  async (thunkAPI) => {
    try {
      const {data} = await CartService.cartList();
      return { cart: data };
    } catch (error) {
      console.log({ error });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addToCart = createAsyncThunk(
  "auth/student-cart/add",
  async (courseId, thunkAPI) => {
    try {
      const {data} = await CartService.cartAdd(courseId);
      return { course: data };
    } catch (error) {
      console.log({ error });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "auth/student-cart/remove",
  async (courseId, thunkAPI) => {
    try {
      const data = await CartService.cartRemove(courseId);
      return { cart: data };
    } catch (error) {
      console.log({ error });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const clearCart = createAsyncThunk(
  "auth/student-cart/clear",
  async (thunkAPI) => {
    try {
      const data = await CartService.cartClear();
      return { cart: data };
    } catch (error) {
      console.log({ error });
      return thunkAPI.rejectWithValue();
    }
  }
);

const cartSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(cartData.pending, (state, action) => {
        state.cart = null;
      })
      .addCase(cartData.fulfilled, (state, action) => {
        state.cart = action?.payload?.cart;
      })
      .addCase(cartData.rejected, (state, action) => {
        state.cart = false;
      })
      .addCase(addToCart.pending, (state, action) => {
        state.cart = state.cart;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.cart = state.cart;
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.cart = state.cart;
      })
      .addCase(removeFromCart.fulfilled, (state, action) => {
        const course = action?.payload?.course;
        state.cart = Object.assign({}, state.cart, {
          course,
        });
      })
      .addCase(clearCart.fulfilled, (state, action) => {
        state.cart = null;
      });
  },
});

const { reducer } = cartSlice;
export default reducer;
