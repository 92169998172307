// i18n.js
import i18n from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";


const now = new Date();
const formattedDate = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  now.getHours(),
  now.getMinutes()
);
const version = formattedDate.valueOf();

i18n
.use(Backend).use(LanguageDetector)
  .init({
    fallbackLng: "ar",
    debug: false,
    backend: {
        // for all available options read the backend's repository readme file
        loadPath: `/locales/{{lng}}/{{ns}}.json?v=${version}`
    },
    // have a common namespace used around the full app
    ns: ["home"],
    defaultNS: "home",

    // keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },
  });


export default i18n;
