import ToastContext from "./ToastService";
import {useState} from "react";
import {BOTTOM_CENTER, BOTTOM_LEFT, BOTTOM_RIGHT, TOP_CENTER, TOP_LEFT, TOP_RIGHT} from "../../enums/ToastAlignEnums";
import ToastElements from "./ToastElements";

export default function ToastProvider({children}) {
    const initialObject = {
        type: "success", position: TOP_RIGHT, timeout: 5000, component: "", progress: true, progressBarClassName: null
    }
    const [toasts, setToasts] = useState([]);

    const open = (object) => {
        object.id = Date.now();
        //need to work based on the direction of the language
        if (object.position) {
            switch (object.position) {
                case TOP_LEFT:
                    break;
                default:
                    break;
            }
        }
        for (let prop in object) {
            initialObject[prop] = object[prop];
        }
        setToasts(toasts => [...toasts, initialObject]);
       // setTimeout(() => close(initialObject.id), (initialObject.timeout + 100));
    }

    const close = (id) => setToasts((toasts) => toasts.filter((toast) => toast.id !== id))

    const success = (component, object = {}) => {
        object.type = 'success';
        object.component = component;
        open(object);
    }

    const error = (component, object = {}) => {
        object.type = 'error';
        object.component = component;
        open(object);
    }

    const warning = (component, object = {}) => {
        object.type = 'warning';
        object.component = component;
        open(object);
    }

    const info = (component, object = {}) => {
        object.type = 'info';
        object.component = component;
        open(object);
    }

    const custom = (component, object = {}) => {
        object.type = 'custom';
        object.component = component;
        open(object);
    }

    const  dismiss = () => {
        setToasts([]);
    }

        return (<ToastContext.Provider value={{success, error, warning, info, custom, close, dismiss}}>
        {children}
        <div className={"space-y-2 fixed h-auto z-toast top-4 left-4 right-4 lg:right-auto lg:left-auto"}>
            {toasts.filter((ele) => ele.position === TOP_LEFT).map((toast) => (
                <ToastElements key={toast.id} close={close} toast={toast}/>))}
        </div>
        <div className={"space-y-2 fixed h-auto z-toast top-4 left-1/2 right-auto -translate-x-1/2"}>
            {toasts.filter((ele) => ele.position === TOP_CENTER).map((toast) => (
                <ToastElements key={toast.id} close={close} toast={toast}/>))}
        </div>
        <div className={"space-y-2 fixed h-auto z-toast flex flex-col top-4 right-4 left-4 lg:left-auto items-end"}>
            {toasts.filter((ele) => ele.position === TOP_RIGHT).map((toast) => (
                <ToastElements key={toast.id} close={close} toast={toast}/>))}
        </div>
        <div
            className={"space-y-2 fixed h-auto z-toast flex flex-col bottom-4 right-4 left-4 lg:right-auto"}>
            {toasts.filter((ele) => ele.position === BOTTOM_LEFT).map((toast) => (
                <ToastElements key={toast.id} close={close} toast={toast}/>))}
        </div>
        <div
            className={"space-y-2 fixed h-auto z-toast flex flex-col bottom-4 left-1/2 right-auto -translate-x-1/2"}>
            {toasts.filter((ele) => ele.position === BOTTOM_CENTER).map((toast) => (
                <ToastElements key={toast.id} close={close} toast={toast}/>))}
        </div>
        <div
            className={"space-y-2 fixed h-auto z-toast flex flex-col bottom-4 right-4 left-4 lg:left-auto items-end"}>
            {toasts.filter((ele) => ele.position === BOTTOM_RIGHT).map((toast) => (
                <ToastElements key={toast.id} close={close} toast={toast}/>))}
        </div>
    </ToastContext.Provider>)
}