import React from 'react'

const IconSearch2 = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <g clip-path="url(#clip0_1612_176)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.61 2.9635C10.4158 2.9635 2.9624 10.4169 2.9624 19.6111C2.9624 28.8053 10.4158 36.2587 19.61 36.2587C23.4085 36.2587 26.9098 34.9865 29.7112 32.8451L34.7259 37.8467C35.3776 38.4967 36.4329 38.4954 37.0829 37.8437C37.7329 37.1919 37.7315 36.1367 37.0798 35.4866L32.1459 30.5656C34.7062 27.6381 36.2576 23.8058 36.2576 19.6111C36.2576 10.4169 28.8042 2.9635 19.61 2.9635ZM6.29574 19.6111C6.29574 12.2578 12.2567 6.29683 19.61 6.29683C26.9633 6.29683 32.9243 12.2578 32.9243 19.6111C32.9243 26.9644 26.9633 32.9254 19.61 32.9254C12.2567 32.9254 6.29574 26.9644 6.29574 19.6111Z" fill="currentcolor" />
            </g>
            <defs>
                <clipPath id="clip0_1612_176">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconSearch2
