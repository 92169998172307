import React from 'react'
import ProfileDropdown from '../../components/common/ProfileDropdown'
import IconSearch2 from '../../components/svg-components/IconSearch2'
import IconVpnDisabled from '../../components/svg-components/IconVpnDisabled'
import IconVpn from '../../components/svg-components/IconVpn'

const DashHeader = ({ toogle, setToogle, widthSize }) => {
  return (
    <header className={`dashboard-header fixed top-0 end-0 py-4 lg:py-5 px-5 sm:px-8 lg:px-12 xxl:px-16 z-40 bg-black xl:bg-transparent 2xs:backdrop-blur-[5px] transition-all start-0 duration-[400ms] ${toogle && widthSize >= 1200 ? "xl:start-[100px]" : "xl:start-[280px]"}`}>
      <div className="flex md:grid grid-cols-4 items-center">
        <div className="col-span-3 flex-grow md:flex-grow-0 search-part flex items-center gap-4 lg:gap-8 pe-4 lg:pe-6 xxl:pe-10 border-e-2 border-white">
          <div className="search-input relative flex-grow">
            <input type="text" className='w-full py-3 lg:py-4 ps-8 xsm:ps-16 pe-4 rounded-sm bg-transparent leading-[1] border-2 border-white/20 text-14 lg:text-16 text-gray-200 outline-none placeholder-gray-200' placeholder='Search by Courses or Events' />
            <span className='absolute top-[12px] lg:top-[14px] start-2  xsm:start-6'><IconSearch2 className="w-5 h-5 lg:w-6 lg:h-6 text-gray-200" /></span>
          </div>
          <button className='py-[10.5px] lg:py-4 px-4 text-14 lg:text-16 font-500 text-white border border-gray-200 rounded-sm hidden md:block'>
            VPN Not Connected
          </button>
          <button className='block md:hidden'>
            <IconVpn className="w-8 h-8" />
            <IconVpnDisabled className="w-8 h-8 hidden" />
          </button>
        </div>
        <div className="profile-part ps-4 lg:ps-6 xxl:ps-10">
          <div className="w-fit">
            <ProfileDropdown />
          </div>
        </div>
      </div>
    </header>
  )
}

export default DashHeader
