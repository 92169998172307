import React from 'react'

const IconVpnDisabled = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <g clip-path="url(#clip0_1728_71)">
                <path d="M37.2467 6.01795L20.0985 0.0375117C19.9485 -0.0125039 19.777 -0.0125039 19.6269 0.0375117L2.47872 6.01795C2.19292 6.11798 2 6.3895 2 6.68959V20.4225C2 29.804 7.55173 36.2631 18.4909 39.6142L19.6484 39.9714C19.7198 39.9929 19.7841 40 19.8556 40C19.927 40 19.9985 39.9929 20.0628 39.9714L21.2203 39.6142C32.1666 36.2631 37.7112 29.8111 37.7112 20.4225V6.69674C37.7112 6.39664 37.5182 6.12513 37.2324 6.0251L37.2467 6.01795Z" fill="#F1F2F2" />
                <path d="M32.96 8.89033L20.0989 4.32462C19.9444 4.26744 19.7746 4.26744 19.6202 4.32462L6.75899 8.89033C6.47319 8.99036 6.28027 9.26188 6.28027 9.56197V20.4011C6.28027 27.9963 10.5245 32.855 19.6416 35.6916C19.713 35.713 19.7845 35.7201 19.8559 35.7201C19.9274 35.7201 19.9988 35.713 20.0703 35.6916C29.1874 32.855 33.4316 27.9963 33.4316 20.4011V9.56197C33.4316 9.26188 33.2387 8.99036 32.9529 8.89033H32.96Z" fill="#707075" />
                <path d="M20.7191 16.0058H17.8611C17.4681 16.0058 17.1466 16.3273 17.1466 16.7203V22.4364C17.1466 22.8294 17.4681 23.1509 17.8611 23.1509C18.2541 23.1509 18.5756 22.8294 18.5756 22.4364V20.2929H20.7191C21.8981 20.2929 22.8627 19.3283 22.8627 18.1493C22.8627 16.9704 21.8981 16.0058 20.7191 16.0058ZM20.7191 18.8638H18.5756V17.4348H20.7191C21.1121 17.4348 21.4336 17.7564 21.4336 18.1493C21.4336 18.5423 21.1121 18.8638 20.7191 18.8638ZM29.2932 16.0058C28.9003 16.0058 28.5787 16.3273 28.5787 16.7203V20.2929L25.5778 16.2916C25.392 16.0487 25.0705 15.9415 24.7775 16.0415C24.4846 16.1416 24.2917 16.4131 24.2917 16.7203V22.4364C24.2917 22.8294 24.6132 23.1509 25.0062 23.1509C25.3992 23.1509 25.7207 22.8294 25.7207 22.4364V18.8638L28.7216 22.8651C28.8574 23.0509 29.0717 23.1509 29.2932 23.1509C29.3718 23.1509 29.4433 23.1366 29.5219 23.1152C29.6638 23.0672 29.7871 22.9759 29.8742 22.8541C29.9614 22.7323 30.0081 22.5862 30.0077 22.4364V16.7203C30.0077 16.3273 29.6862 16.0058 29.2932 16.0058ZM15.2531 16.0487C15.0758 15.9833 14.88 15.9901 14.7076 16.0676C14.5353 16.1451 14.4002 16.2871 14.3314 16.4631L12.8595 20.3929L11.3876 16.4631C11.3551 16.3748 11.3053 16.2938 11.2412 16.225C11.177 16.1561 11.0997 16.1008 11.0139 16.0622C10.928 16.0236 10.8353 16.0025 10.7413 16.0002C10.6472 15.9979 10.5536 16.0144 10.4659 16.0487C10.0944 16.1844 9.9086 16.5989 10.0444 16.9704L12.1879 22.6865C12.2951 22.9651 12.5594 23.1509 12.8595 23.1509C13.1596 23.1509 13.424 22.9651 13.5312 22.6865L15.6747 16.9704C15.8104 16.5989 15.6247 16.1916 15.2531 16.0487Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1728_71">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconVpnDisabled