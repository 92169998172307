import React from 'react'

const IconVpn = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <g clip-path="url(#clip0_1728_79)">
                <path d="M37.2467 6.01795L20.0985 0.0375117C19.9485 -0.0125039 19.777 -0.0125039 19.6269 0.0375117L2.47872 6.01795C2.19292 6.11798 2 6.3895 2 6.68959V20.4225C2 29.804 7.55173 36.2631 18.4909 39.6142L19.6484 39.9714C19.7198 39.9929 19.7841 40 19.8556 40C19.927 40 19.9985 39.9929 20.0628 39.9714L21.2203 39.6142C32.1666 36.2631 37.7112 29.8111 37.7112 20.4225V6.69674C37.7112 6.39664 37.5182 6.12513 37.2324 6.0251L37.2467 6.01795Z" fill="#F1F2F2" />
                <path d="M32.96 8.89033L20.0989 4.32462C19.9444 4.26744 19.7746 4.26744 19.6202 4.32462L6.75899 8.89033C6.47319 8.99036 6.28027 9.26188 6.28027 9.56197V20.4011C6.28027 27.9963 10.5245 32.855 19.6416 35.6916C19.713 35.713 19.7845 35.7201 19.8559 35.7201C19.9274 35.7201 19.9988 35.713 20.0703 35.6916C29.1874 32.855 33.4316 27.9963 33.4316 20.4011V9.56197C33.4316 9.26188 33.2387 8.99036 32.9529 8.89033H32.96Z" fill="#B11516" />
                <path d="M21.2924 17.1518H18.4343C18.0414 17.1518 17.7198 17.4733 17.7198 17.8663V23.5824C17.7198 23.9754 18.0414 24.2969 18.4343 24.2969C18.8273 24.2969 19.1488 23.9754 19.1488 23.5824V21.4389H21.2924C22.4713 21.4389 23.4359 20.4743 23.4359 19.2953C23.4359 18.1164 22.4713 17.1518 21.2924 17.1518ZM21.2924 20.0098H19.1488V18.5808H21.2924C21.6853 18.5808 22.0069 18.9024 22.0069 19.2953C22.0069 19.6883 21.6853 20.0098 21.2924 20.0098ZM29.8665 17.1518C29.4735 17.1518 29.152 17.4733 29.152 17.8663V21.4389L26.151 17.4376C25.9653 17.1947 25.6437 17.0875 25.3508 17.1875C25.0578 17.2876 24.8649 17.5591 24.8649 17.8663V23.5824C24.8649 23.9754 25.1864 24.2969 25.5794 24.2969C25.9724 24.2969 26.2939 23.9754 26.2939 23.5824V20.0098L29.2949 24.0111C29.4306 24.1969 29.645 24.2969 29.8665 24.2969C29.9451 24.2969 30.0165 24.2826 30.0951 24.2612C30.237 24.2132 30.3603 24.1219 30.4475 24.0001C30.5347 23.8783 30.5814 23.7322 30.581 23.5824V17.8663C30.581 17.4733 30.2595 17.1518 29.8665 17.1518ZM15.8264 17.1947C15.6491 17.1293 15.4532 17.1361 15.2809 17.2136C15.1085 17.2911 14.9734 17.4331 14.9047 17.6091L13.4328 21.5389L11.9609 17.6091C11.9284 17.5208 11.8786 17.4398 11.8144 17.371C11.7502 17.3021 11.6729 17.2468 11.5871 17.2082C11.5013 17.1696 11.4086 17.1485 11.3145 17.1462C11.2204 17.1439 11.1268 17.1604 11.0392 17.1947C10.6676 17.3304 10.4818 17.7448 10.6176 18.1164L12.7611 23.8325C12.8683 24.1111 13.1327 24.2969 13.4328 24.2969C13.7329 24.2969 13.9972 24.1111 14.1044 23.8325L16.2479 18.1164C16.3837 17.7448 16.1979 17.3376 15.8264 17.1947Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1728_79">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconVpn