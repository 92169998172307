import React from 'react'
import { cn } from '../../lib/utils'
import ErrorMessagePara from '../common/ErrorMessage'

const Input = ({ suffixIcon, prefixIcon, size, inputClassName, className, iconClassName, type, placeholder, groupClassName, disabled, suffixWrap, prefixWrap, errorType, textarea, ...props }) => {
    return (
        <div className={cn(`form-group mb-4 lg:mb-6`, groupClassName)}>
            {textarea == true ?
                <textarea name="" className={cn(`w-full border border-white py-2 sm:py-2.5 px-4 md:px-6 rounded-sm required:border-danger-950 invalid:border-danger-950 ${disabled ? "opacity-50" : ""}`, className)} id="" {...props}></textarea>
                : <div className={cn(`flex gap-3 border  border-white py-3 px-4 rounded-sm items-center w-full required:border-danger-950 invalid:border-danger-950 ${disabled ? "opacity-50" : ""}`, className)}>
                    {prefixWrap ? prefixWrap : prefixIcon ? <button ariaLabel="button" type="button" className={cn(`left_icon`, iconClassName)}>{prefixIcon}</button> : <></>}
                    <input type={type ? type : ""} className={cn(`peer w-full text-white text-14 font-400 placeholder:text-gray-300 bg-transparent border-0 outline-none`, inputClassName)} placeholder={placeholder ? placeholder : ""} {...props} />
                    {suffixWrap ? suffixWrap : suffixIcon ? <button ariaLabel="button" type="button" className={cn(`right_icon`, iconClassName)}>{suffixIcon}</button> : <></>}
                </div>
            }
            <ErrorMessagePara errorType={errorType} />
        </div>
    )
}

export default Input    