import { useRoutes } from "react-router-dom";
import { Suspense, lazy } from "react";
import LoadingScreen from "../components/loading/LoadingScreen";
import MainLayout from "../layout/MainLayout";
import DashboardLayout from "../layout/dashboard/DashMaster";
import { PATHS, PATH_HOME } from "./path";
import DashMaster from "../layout/dashboard/DashMaster";
import CourseMaterials from "../pages/dashboard/CourseMaterials";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
      <LoadingScreen />
    </Suspense>
  );
};
const Landing = Loadable(lazy(() => import("../pages/home")));
const Course = Loadable(lazy(() => import("../pages/courses/Lists")));
const CourseDetail = Loadable(lazy(() => import("../pages/courses/Detail")));
const Login = Loadable(lazy(() => import("../../src/pages/auth/Login")));
const Leaderboard = Loadable(lazy(() => import("../../src/pages/leaderboard/Leaderboard")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/DashBoard")));
const Profile = Loadable(lazy(() => import("../pages/dashboard/Profile")));
const CourseLeaderboard = Loadable(lazy(() => import("../pages/dashboard/CourseLeaderboard")));
const MyCourses = Loadable(lazy(() => import("../pages/dashboard/MyCourses")));

export default function Router() {
  return useRoutes([
    {
      path: PATH_HOME.root,
      element: (
        <MainLayout>
          <Landing />
        </MainLayout>
      ),
    },
    {
      path: "/courses",
      element: (
        <MainLayout>
          <Course />
        </MainLayout>
      ),
    },
    {
      path: "/course-detail",
      element: (
        <MainLayout>
          <CourseDetail />
        </MainLayout>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <DashboardLayout>
          <Dashboard />
        </DashboardLayout>
      ),
    },
    {
      path: "/dashboard/course-materials",
      element: (
        <DashboardLayout>
          <CourseMaterials />
        </DashboardLayout>
      ),
    },
    {
      path: "/dashboard/leaderboard",
      element: (
        <DashboardLayout>
          <Leaderboard />
        </DashboardLayout>
      ),
    },
    {
      path: "/leaderboard",
      element: (
        <MainLayout>
          <Leaderboard />
        </MainLayout>
      ),
    },
    {
      path:"/dashboard/profile",
      element: (
        <DashboardLayout>
          <Profile />
        </DashboardLayout>
      ),
    },
    {
      path: "/dashboard/course-leaderboard",
      element: (
        <DashboardLayout>
          <CourseLeaderboard />
        </DashboardLayout>
      ),
    },
    {
      path: "/dashboard/my-courses",
      element: (
        <DashboardLayout>
          <MyCourses />
        </DashboardLayout>
      ),
    },
    {
      path: PATHS.signIn,
      element: <Login />,
    },
  ]);
}
