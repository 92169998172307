import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/Logo.svg";
import IconClose from '../svg-components/IconClose';
import IconProfile from '../svg-components/IconProfile';
import { Button } from "../ui/Button";
import Input from '../ui/Input';

const ResetPasswordModal = ({ resetPasswordModalOpen, setResetPasswordModalOpen }) => {
    const handleClose = () => {
        setResetPasswordModalOpen(false)
    }

    return (
        <Transition appear show={resetPasswordModalOpen}>
            <Dialog as="div" className="relative z-50" onClose={() => setResetPasswordModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-50" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-lg transform rounded-md bg-black shadow-modal transition-all font-gilroy">
                                <div className="dialog-content p-4 md:p-6 lg:p-8 relative">
                                    <span className='close absolute top-6 right-6 cursor-pointer' onClick={handleClose}>
                                        <IconClose className="w-5 h-5 text-white text-dan" />
                                    </span>
                                    <div className='dialog-header mb-10'>
                                        <div className="logo-part text-center mb-4">
                                            <img src={Logo} alt="" className='mx-auto  max-w-[220px] md:max-w-[260px]' />
                                        </div>
                                        <div className="heading-part">
                                            <h5 className='!text-2xl mb-0 text-center'>
                                                Reset Password
                                            </h5>
                                        </div>
                                    </div>
                                    <div className='dialog-body'>
                                        <form action="">
                                            <div className="form-item">
                                                <label htmlFor="" className='text-base text-white font-400 mb-2 block'>Email Address</label>
                                                <Input prefixWrap={<span><IconProfile className="w-5 h-5 text-gray-700" /></span>} placeholder={"Username/Email Address"} />
                                            </div>
                                            <Button className="w-full text-16 font-medium mt-6" variant="" onClick={handleClose}>
                                                Reset
                                            </Button>
                                        </form>
                                        <div className='mt-6'>
                                            <p className='text-center text-16 font-medium flex items-center justify-center gap-[6px]'>Already Have an Account? <Link className='text-danger-950 font-bold font-gilroy'>Login Here</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default ResetPasswordModal
