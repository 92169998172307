import CheckIcon from "../../components/svg-components/CheckIcon";
import ExclamationCircleIcon from "../../components/svg-components/ExclamationCircleIcon";
import ExclamationIcon from "../../components/svg-components/ExclamationIcon";
import InformationCircleIcon from "../../components/svg-components/InformationCircleIcon";
import XIcon from "../../components/svg-components/XIcon";
import { useEffect, useState } from "react";

export default function ToastElements({ toast, close }) {
  const [progress, setProgress] = useState(100);
  const [toastInterval, setToastInterval] = useState();
  const [progressBarBackgroundClasses, setProgressBarBackgroundClasses] = useState("");
  const [isHover, setIsHover] = useState(true);

  useEffect(() => {
    if (isHover) {
      const progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          return prevProgress - 1;
        });
      }, toast.timeout / 100);
      setToastInterval(progressInterval);
      return () => {
        clearInterval(progressInterval);
      };
    }
  }, [isHover]);

  useEffect(() => {
    let classes;
    switch (toast.type) {
      case "success":
        classes = "bg-green-500";
        break;
      case "error":
        classes = "bg-red-500";
        break;
      case "warning":
        classes = "bg-yellow-500";
        break;
      case "info":
        classes = "bg-blue-500";
        break;
      default:
        classes = "";
        break;
    }
    setProgressBarBackgroundClasses(toast.progressBarClassName ?? classes);
  }, [toast]);

  const stopProgress = () => {
    setIsHover(false);
    clearInterval(toastInterval);
  };

  useEffect(() => {
    if (progress == 0) close(toast.id);
  }, [progress]);

  const remainProgress = () => {
    setIsHover(true);
  };

  return (
    <div
      onMouseEnter={() => stopProgress()}
      onMouseLeave={() => remainProgress()}
      className={
        "relative w-full lg:min-w-lg h-full overflow-hidden max-w-fit bg-white rounded-lg shadow-xl"
      }
    >
      <div className={"p-2 flex justify-start items-start text-black gap-4"}>
        {(() => {
          switch (toast.type) {
            case "success":
              return (
                <span className="bg-green-100 p-1 rounded-lg">
                  <CheckIcon className="stroke-green-500 h-6 w-6" />
                </span>
              );
            case "error":
              return (
                <span className="bg-red-100 p-1 rounded-lg">
                  <ExclamationCircleIcon className="stroke-red-500 h-6 w-6" />
                </span>
              );
            case "warning":
              return (
                <span className="bg-yellow-100 p-1 rounded-lg">
                  <ExclamationIcon className="stroke-yellow-500 h-6 w-6" />
                </span>
              );
            case "info":
              return (
                <span className="bg-blue-100 p-1 rounded-lg">
                  <InformationCircleIcon className="stroke-blue-500 h-6 w-6" />
                </span>
              );
            default:
              return <></>;
          }
        })()}
        <div className={"grow"}>{toast.component}</div>
        <button
          type={"button"}
          onClick={() => close(toast.id)}
          className={"p-1 ml-2 rounded-lg bg-gray-50 text-gray-800/60"}
        >
          <XIcon className={"h-6 w-6 text-gray-500"} />
        </button>
      </div>
      {toast.progress ? (
        <div className="relative w-full h-1 overflow-hidden bg-white">
          <span
            style={{ width: `${progress}%` }}
            className={
              "absolute h-full duration-300 ease-linear " +
              progressBarBackgroundClasses
            }
          ></span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
