import React from 'react'

const IconNotification = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <path d="M37.4166 17.9165C36.7266 17.9165 36.1666 17.3565 36.1666 16.6665C36.1666 11.8798 34.3034 7.38147 30.92 3.99647C30.4317 3.50819 30.4317 2.71655 30.92 2.22827C31.4083 1.73999 32.1999 1.73999 32.6884 2.22827C36.5434 6.08483 38.6666 11.2133 38.6666 16.6665C38.6666 17.3565 38.1066 17.9165 37.4166 17.9165ZM3.25 17.9165C2.56 17.9165 2 17.3565 2 16.6665C2 11.2133 4.12344 6.08483 7.97992 2.22983C8.4682 1.74155 9.26016 1.74155 9.74844 2.22983C10.2367 2.71812 10.2367 3.51007 9.74844 3.99835C6.36344 7.38147 4.5 11.8798 4.5 16.6665C4.5 17.3565 3.94 17.9165 3.25 17.9165ZM20.3334 39.9999C16.8868 39.9999 14.0834 37.1966 14.0834 33.7499C14.0834 33.0599 14.6434 32.4999 15.3334 32.4999C16.0234 32.4999 16.5834 33.0599 16.5834 33.7499C16.5834 35.8184 18.2649 37.4999 20.3334 37.4999C22.4016 37.4999 24.0834 35.8184 24.0834 33.7499C24.0834 33.0599 24.6434 32.4999 25.3334 32.4999C26.0234 32.4999 26.5834 33.0599 26.5834 33.7499C26.5834 37.1966 23.7801 39.9999 20.3334 39.9999Z" fill="currentcolor" />
            <path d="M34.0834 35H6.58338C4.97479 35 3.6665 33.6917 3.6665 32.0834C3.66624 31.6622 3.7573 31.2458 3.93343 30.8631C4.10956 30.4804 4.36657 30.1405 4.68674 29.8666C7.2215 27.7249 8.6665 24.6118 8.6665 21.3135V16.6666C8.6665 10.2334 13.8999 5 20.3334 5C26.7665 5 31.9999 10.2334 31.9999 16.6666V21.3134C31.9999 24.6119 33.4449 27.7249 35.9632 29.8551C36.6282 30.4217 36.9999 31.2298 36.9999 32.0834C36.9999 33.6917 35.6917 35 34.0834 35ZM20.3334 7.5C15.2781 7.5 11.1665 11.6116 11.1665 16.6666V21.3134C11.1665 25.3482 9.3983 29.1584 6.31666 31.7633C6.25838 31.8133 6.1665 31.9168 6.1665 32.0834C6.1665 32.3098 6.35666 32.5 6.58338 32.5H34.0834C34.3098 32.5 34.4999 32.3098 34.4999 32.0834C34.4999 31.9168 34.4084 31.8134 34.3531 31.7666C31.2681 29.1584 29.4999 25.3482 29.4999 21.3135V16.6666C29.4999 11.6116 25.3883 7.5 20.3334 7.5Z" fill="currentcolor" />
            <path d="M20.3335 7.5C19.6435 7.5 19.0835 6.94 19.0835 6.25V1.25C19.0835 0.56 19.6435 0 20.3335 0C21.0235 0 21.5835 0.56 21.5835 1.25V6.25C21.5835 6.94 21.0235 7.5 20.3335 7.5Z" fill="currentcolor" />
        </svg>
    )
}

export default IconNotification
