import { useEffect } from "react";
import Router from "./routes";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import ToastProvider from "./providers/toast/ToastProvider";
import Http from "./security/Http";
import { connect } from "react-redux";

function App(props) {
  
  if (
    localStorage.getItem("i18nextLng") === "" ||
    localStorage.getItem("i18nextLng") === null ||
    localStorage.getItem("i18nextLng") === "en-GB"
  ) {
    localStorage.setItem("i18nextLng", "ar");
    i18n.changeLanguage("ar");
  }

  if (
    localStorage.getItem("token") !== "" &&
    localStorage.getItem("token") !== null
  ) {
    Http.setBearerToken(localStorage.getItem("token"));
  }

  //Restore theme if found in localsotrage
  useEffect(() => {
    const savedMode = localStorage.getItem("theme");
    if (savedMode) {
      document.documentElement.classList.add(savedMode);
    }
    // console.log({t});
  }, []);

  // By default dark theme as per current template. below code can be converted into toggle button for theme.
  useEffect(() => {
    // if (document.documentElement.classList.contains("dark")) {
    //   document.documentElement.classList.remove("dark");
    //   localStorage.setItem("theme", "light");
    // } else {
    document.documentElement.classList.add("dark");
    localStorage.setItem("theme", "dark");
    // }
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <ToastProvider>
        <Router />
      </ToastProvider>
    </I18nextProvider>
  );
}

const mapStateToProps = function (state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(App);
