import React from 'react'

const IconLogout = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <g clip-path="url(#clip0_1609_60)">
                <g clip-path="url(#clip1_1609_60)">
                    <path d="M15.2381 10.3333V6.52381C15.2381 5.51346 15.6395 4.5445 16.3539 3.83007C17.0683 3.11565 18.0373 2.71429 19.0476 2.71429H36.1905C37.2008 2.71429 38.1698 3.11565 38.8842 3.83007C39.5986 4.5445 40 5.51346 40 6.52381V33.1905C40 34.2008 39.5986 35.1698 38.8842 35.8842C38.1698 36.5986 37.2008 37 36.1905 37H19.0476C18.0373 37 17.0683 36.5986 16.3539 35.8842C15.6395 35.1698 15.2381 34.2008 15.2381 33.1905V29.381H19.0476V33.1905H36.1905V6.52381H19.0476V10.3333H15.2381ZM10.4762 30.3333L13.1695 27.64L7.29143 21.7619H26.6667V17.9524L7.29143 17.9524L13.1695 12.0743L10.4762 9.38095L-1.19209e-07 19.8571L10.4762 30.3333Z" fill="currentcolor" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1609_60">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
                <clipPath id="clip1_1609_60">
                    <rect width="40" height="40" fill="white" transform="matrix(-1 0 0 -1 40 40)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconLogout
