import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'
import { cn } from '../../lib/utils'

const buttonVariance = cva(
    "text-center border border-danger-950 capitalize hover:border-danger-500 flex items-center rounded-xs justify-center gap-[16px] leading-[1]",
    {
        variants: {
            variant: {
                fill: 'bg-danger-950 text-base font-bold hover:bg-danger-500',
            },
            size: {
                default: 'py-2.5 px-4',
                sm: 'py-2 px-3.5',
            }
        },
        defaultVariants: {
            size: "default",
            variant: 'fill'
        }
    },
)

const Button = forwardRef(({ children, variant, size, className, ...props }, ref) => {
    return (
        <button className={cn(buttonVariance({ variant, size, className }))} {...props} ref={ref}>
            {children}
        </button>
    )
})

export { Button, buttonVariance }