import Vimeo from '@u-wave/react-vimeo'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../components/ui/Button'
import { Disclosure } from '@headlessui/react'
import Input from '../../components/ui/Input'
import { IconButton } from '../../components/ui/IconButton'
import SendPlaneIcon from '../../components/icons/SendPlaneIcon'
import IconArrowDown from '../../components/svg-components/IconArrowDown'
import ArrowDownIcon from '../../components/icons/ArrowDownIcon'
import LinkIcon from '../../components/icons/LinkIcon'
import { useState } from 'react'

export default function CourseMaterials() {

    const [windowHeightCount, setwindowHeightCount] = useState(document.getElementsByClassName("course-video")[0]?.clientHeight);
    const [windowWidthCount, setwindowWidthCount] = useState(window.innerWidth);

    useEffect(() => {
        setwindowWidthCount(window.innerWidth)
        setwindowHeightCount(document.getElementsByClassName("course-video")[0]?.clientHeight)
        window.addEventListener('resize', function () {
            setwindowHeightCount(document.getElementsByClassName("course-video")[0]?.clientHeight)
        });
    }, [windowHeightCount, windowWidthCount]);
    return (
        <>
            <div className="page-header flex justify-between items-center font-gilroy gap-6 mb-6">
                <h1 className="h3 mb-0">AD101: Introduction Active Directory</h1>
                <div className="tabs"></div>
            </div>
            <div className="font-gilroy pb-8">
                <div className="grid grid-cols-1 xl:grid-cols-6 xxl:grid-cols-4 gap-6">
                    <div className="xl:col-span-4 xxl:col-span-3 left_part">
                        <div className="course-video xxl:mb-8 mb-6">
                            <Vimeo
                                className="w-full aspect-video vimoe-video-wrap"
                                video="https://player.vimeo.com/video/115783408?h=def2b8dd8f"
                                autoplay
                                width={'100%'}
                                height={'100%'}
                            />
                        </div>
                        <div className="course-next-prev-button flex justify-between gap-4 items-center xxl:mb-8 xl:mb-6 mb-2">
                            <Button className="bg-dark-730 px-2 py-2 lg:py-3 rounded-sm text-xl min-w-[130px] sm:min-w-[150px]">Previous</Button>
                            <Button className="bg-dark-730 px-2 py-2 lg:py-3 rounded-sm text-xl min-w-[130px] sm:min-w-[150px]">Next</Button>
                        </div>
                    </div>
                    <div className="xl:col-span-2 xxl:col-span-1 right_part">
                        <div className="chapter_list rounded-md xl:rounded-tl-none xl:rounded-bl-none xl:rtl:rounded-tr-none xl:rtl:rounded-br-none overflow-hidden bg-dark-730 xl:mb-8 mb-6">
                            <div className={`theme-scrollbar min-h-[100%] overflow-y-auto ${windowWidthCount < 992 ? "max-h-[500px]" : ""}`} style={{ maxHeight : windowWidthCount > 992 ? `calc(${windowHeightCount+'px'} - 60px)` : ""}}>
                                {
                                    [...Array(20)].map((data, ind) => (
                                        <Link to={`/dashboard/course-materials`} key={ind}>
                                            <div className="chapter_itrm border-b p-3 lg:p-4" key={ind + 1}>
                                                <h6 className="mb-2 !text-base lg:!text-lg">Chapter {ind + 1}</h6>
                                                <p className="line-clamp-2 text-sm">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas numquam laborum nulla minus amet cupiditate voluptatum est delectus magni officiis praesentium vel, aliquam impedit incidunt dolores. Fugiat ipsum libero blanditiis.</p>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </div>
                            <div className="chapter_list bg-black p-4 py-2"><Button className="p-0 py-2 bg-transparent hover:bg-transparent text-lg w-full">All Videos</Button></div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-6 xxl:grid-cols-4 gap-6">
                    <div className="xl:col-span-4 xxl:col-span-3 left_part">
                        <div className="mb-6 xxl:mb-8">
                            <Disclosure>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="py-2 bg-dark-730 lg:py-3 xxl:py-4 px-4 xxl:px-6 rounded-[8px] xxl:rounded-sm text-base xxl:text-xl font-medium w-full text-start flex items-start justify-between gap-4"> Quiz <span className="mt-[3px] xxl:mt-1"><ArrowDownIcon className={`xxl:w-6 xxl:h-6 w-5 h-5 ${open ? "rotate-180" : ""}`} /> </span></Disclosure.Button>
                                        <Disclosure.Panel className="text-gray-500 bg-black rounded-md rounded-tl-none rounded-tr-none xxl:p-6 p-3 md:p-4 py-6 max-w-[95%] w-full mx-auto">
                                            <div className="mb-6">
                                                <label htmlFor="" className="mb-2 block">what is your name ? </label>
                                                <Input errorType={"incorrect"} suffixIcon={"verify"} groupClassName="!mb-0" />
                                            </div>
                                            <div className="qa_dots_list">
                                                <ul className="flex justify-center items-center gap-3">
                                                    <li className="w-4 h-4 border block rounded bg-success-700 border-success-700"></li>
                                                    <li className="w-4 h-4 border block rounded bg-white border-white"></li>
                                                    {
                                                        [...Array(15)].map((data, ind) => (
                                                            <li className="w-4 h-4 border block rounded border-white"></li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>
                        <div className="">
                            <Disclosure>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="py-2 bg-dark-730 lg:py-3 xxl:py-4 px-4 xxl:px-6 rounded-[8px] xxl:rounded-sm text-base xxl:text-xl font-medium w-full text-start flex items-start justify-between gap-4"> Show Comments <span className="mt-[3px] xxl:mt-1"><ArrowDownIcon className={`xxl:w-6 xxl:h-6 w-5 h-5 ${open ? "rotate-180" : ""}`} /> </span></Disclosure.Button>
                                        <Disclosure.Panel className="text-gray-500 bg-black rounded-md rounded-tl-none rounded-tr-none xxl:p-6 p-3 md:p-4 py-6 max-w-[95%] w-full mx-auto">
                                            {
                                                [...Array(3)].map((data, ind) => (
                                                    <div className="flex items-start justify-start xxl:gap-6 gap-4 mb-6" key={ind}>
                                                        <div className="user-image xxl:w-14 xxl:h-14 sm:w-10 sm:h-10 w-8 h-8 flex-shrink-0">
                                                            <img src={require('../../assets/images/img4.jpg')} className="rounded-full object-cover" alt="" />
                                                        </div>
                                                        <div className="commnet-part p-3 md:p-4 xxl:p-6 bg-dark-730 rounded-sm">
                                                            <h6 className="mb-3 md:mb-4 !text-sm sm:!text-base lg:!text-lg">What is Active Directory?</h6>
                                                            <div className="instructor-commnet-part p-3 md:p-4 xxl:p-6 bg-black rounded-sm">
                                                                <div className="flex flex-wrap items-center justify-start gap-4 mb-4">
                                                                    <div className="user-image xxl:w-14 xxl:h-14 sm:w-10 sm:h-10 w-8 h-8 flex-shrink-0">
                                                                        <img src={require('../../assets/images/img8.jpg')} className="rounded-full object-cover" alt="" />
                                                                    </div>
                                                                    <h6 className='!text-sm sm:!text-base lg:!text-lg'>Mazen</h6>
                                                                    <label className="bg-danger-850 text-sm text-white px-2 py-1 rounded-[8px] min-w-[100px] text-center block">instructor</label>
                                                                </div>
                                                                <div className="">
                                                                    <p className="text-sm lg:text-base">Active Directory (AD) is a database and set of services that connectusers with the network resources they need to get their work done.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <div className="flex items-center bg-dark-730 md:px-5 md:py-3 p-3 md:gap-4 gap-2 rounded-sm">
                                                <div className="user-image hidden sm:block xxl:w-14 xxl:h-14 sm:w-10 sm:h-10 w-8 h-8 flex-shrink-0">
                                                    <img src={require('../../assets/images/img4.jpg')} className="rounded-full object-cover" alt="" />
                                                </div>
                                                <Input groupClassName={"!mb-0 flex-grow"} className={"w-full bg-black text-white border-black"} placeholder={"What is Active Directory?"} />
                                                <IconButton className={"w-8 h-8"}><SendPlaneIcon className="xxl:w-6 xxl:h-6 lg:w-5 lg:h-5 w-4 h-4" /></IconButton>
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>
                    </div>
                    <div className="xl:col-span-2 xxl:col-span-1 right_part">
                        <div className="">
                            <Disclosure>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="py-2 bg-dark-730 lg:py-3 xxl:py-4 px-4 xxl:px-6 rounded-[8px] xxl:rounded-sm text-base xxl:text-xl font-medium w-full text-start flex items-start justify-between gap-4"> Attachment <span className="mt-[3px] xxl:mt-1"><ArrowDownIcon className={`xxl:w-6 xxl:h-6 w-5 h-5 ${open ? "rotate-180" : ""}`} /> </span></Disclosure.Button>
                                        <Disclosure.Panel className="text-gray-500 bg-black rounded-md rounded-tl-none rounded-tr-none xxl:p-6 p-3 md:p-4 py-6 max-w-[95%] w-full mx-auto">
                                            <div className="text-base text-white flex flex-col gap-3">
                                                {
                                                    [...Array(3)].map((data, ind) => (
                                                        <Link to="" className="flex items-center justify-start gap-3 hover:text-danger-200"><span><LinkIcon className="w-4 h-3" /></span> AD100.pcap </Link>
                                                    ))
                                                }
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
