import React, { useEffect, useState } from 'react'
import IconBoldArrow from '../../components/svg-components/IconBoldArrow'
import Logo from "../../assets/images/Logo.svg";
import LogoFav from "../../assets/images/Logo-Fav.png";
import { Link, useLocation } from 'react-router-dom';
import IconProfile from '../../components/svg-components/IconProfile';
import IconLogout from '../../components/svg-components/IconLogout';
import IconRead from '../../components/svg-components/IconRead';
import IconTrophy from '../../components/svg-components/IconTrophy';
import IconKing from "../../components/svg-components/IconKing";
import IconNotification from "../../components/svg-components/IconNotification";
import IconChat2 from "../../components/svg-components/IconChat2";
import IconSetting from "../../components/svg-components/IconSetting";
import ProfileTiny from "../../assets/images/profile-tiny.png";
import IconClose from '../../components/svg-components/IconClose';

const DashboardSidebar = ({ setToogle, toogle, widthSize, setWidthSize }) => {
    const { pathname } = useLocation()
    return (
        <div className={`fixed font-gilroy top-0 ${toogle ? "start-0" : "2xs:-start-[calc(280px+0px)] -start-full"}  xl:start-0 h-screen bg-black 2xs:border-e 2xs:border-white/20 2xs:rounded-tr-lg 2xs:rounded-br-lg 2xs:rtl:rounded-tl-lg 2xs:rtl:rounded-bl-lg 2xs:rtl:rounded-tr-none 2xs:rtl:rounded-br-none !transition-all w-full !duration-[400ms] group ${toogle && widthSize >= 1200 ? "xl:w-[100px] bar-mini" : "2xs:w-[280px]"}  z-50`}>
            <button className={`w-[30px] lg:w-[40px] h-[54px] lg:h-[70px] 2xs:flex ${toogle ? "hidden" : "flex"} bg-toogle-btn-gradiant  items-center justify-center absolute top-20 -end-[30px]  lg:-end-[40px] rounded-tr-md rounded-br-md rtl:rounded-tr-none rtl:rounded-br-none rtl:rounded-tl-md rtl:rounded-bl-md`} onClick={() => setToogle(!toogle)}>
                <IconBoldArrow className="w-4 h-4 text-dark rtl:rotate-180" />
            </button>
            <button className="absolute block 2xs:hidden top-6 end-6" onClick={() => setToogle(false)}>
                <IconClose className="w-6 h-6 text-white" />
            </button>
            <div className="navbar-brand-box px-6 py-4 xl:p-6">
                <div className="logo-wrapper">
                    <Link to={"/"}>
                        <img src={Logo} alt="" className='transition-all duration-[400ms] max-w-[200px] mx-auto xl:mx-0 xl:max-w-full w-full group-[.bar-mini]:opacity-0 group-[.bar-mini]:invisible group-[.bar-mini]:w-0' />
                    </Link>
                    <Link to={"/"}>
                        <img src={LogoFav} alt="" className='transition-all duration-[400ms] opacity-0 invisible w-0 group-[.bar-mini]:opacity-100  group-[.bar-mini]:w-full group-[.bar-mini]:visible' />
                    </Link>
                </div>
            </div>
            <div className="menu-part h-[calc(100%-153px)] xl:h-[calc(100%-177px)] overflow-y-auto overflow-x-hidden theme-scrollbar py-4">
                <div className="profile-part mb-8">
                    <div className="w-[100px] h-[100px] transition-all mb-4 duration-[400ms] group-[.bar-mini]:h-[48px] group-[.bar-mini]:w-[48px] mx-auto relative hexa rounded-[38px] group-[.bar-mini]:rounded-sm">
                    </div>
                    <h5 className='!text-16 text-center transition-all duration-[400ms]  group-[.bar-mini]:!text-[10px]'>Mazen Alfaif</h5>
                    <h6 className='!text-14 text-center transition-all duration-[400ms] group-[.bar-mini]:!text-[9px] font-300 text-gray-950 mb-2'>@BugsBunny</h6>
                    <div className='flex items-center flex-col'>
                        <span className='mb-1'><IconKing className="w-8 h-8" /></span>
                        <p className='!text-14 text-center transition-all duration-[400ms] group-[.bar-mini]:!text-[9px] font-500 text-white'>  Master Hacker</p>
                    </div>
                </div>
                <ul className='!transition-all !duration-[400ms] whitespace-nowrap'>
                    <li>
                        <Link to={"/dashboard"} className={`${pathname === "/dashboard" ? "active active-link group bg-[#0f0f0f]" : ""}  px-6 py-4 flex items-center group/link gap-4 transition-all duration-[400ms] group-[.bar-mini]:px-[calc(50%-12px)]`}>
                            <span><IconProfile className="w-6 h-6  transition-colors duration-[300ms] group-[.active-link]:text-danger-300 group-hover/link:text-danger-300" /></span>
                            <h6 className='!text-16 font-500  group-hover/link:text-danger-300  group-[.active-link]:text-danger-300 transition-all duration-[300ms] group-[.bar-mini]:opacity-0 group-[.bar-mini]:invisible'>Home</h6>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/dashboard/my-courses"} className={`${pathname === "/dashboard/my-courses" ? "active group active-link bg-[#0f0f0f]" : ""} px-6 py-4 flex items-center group/link gap-4 transition-all duration-[400ms] group-[.bar-mini]:px-[calc(50%-12px)]`}>
                            <span><IconRead className="w-6 h-6 transition-colors duration-[300ms] group-[.active-link]:text-danger-300 group-hover/link:text-danger-300 " /></span>
                            <h6 className='!text-16 font-500  group-hover/link:text-danger-300 transition-all group-[.active-link]:text-danger-300 duration-[300ms] group-[.bar-mini]:opacity-0 group-[.bar-mini]:invisible'>My Courses</h6>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/dashboard/course-leaderboard"} className={`${pathname === "/dashboard/course-leaderboard" ? "active group active-link bg-[#0f0f0f]" : ""}  px-6 py-4 flex items-center group/link gap-4 transition-all duration-[400ms] group-[.bar-mini]:px-[calc(50%-12px)]`}>
                            <span><IconTrophy className="w-6 h-6 transition-colors duration-[300ms] group-[.active-link]:text-danger-300 group-hover/link:text-danger-300" /></span>
                            <h6 className='!text-16 font-500 group-hover/link:text-danger-300 group-[.active-link]:text-danger-300 transition-all duration-[300ms]  group-[.bar-mini]:opacity-0 group-[.bar-mini]:invisible'>Leaderboards</h6>
                        </Link>
                    </li>
                    <li>
                        <Link className='px-6 py-4 flex items-center group/link gap-4 transition-all duration-[400ms] group-[.bar-mini]:px-[calc(50%-12px)]'>
                            <span><IconLogout className="w-6 h-6 transition-colors duration-[300ms] group-hover/link:text-danger-300" /></span>
                            <h6 className='!text-16 font-500 group-hover/link:text-danger-300 transition-all duration-[300ms]  group-[.bar-mini]:opacity-0 group-[.bar-mini]:invisible'>Logout</h6>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="action-part flex-shrink-0 py-4 px-6 bg-[#0f0f0f] 2xs:rounded-br-lg 2xs:rtl:rounded-bl-lg rtl:rounded-br-none  transition-all duration-[300ms]  group-[.bar-mini]:opacity-0 group-[.bar-mini]:invisible">
                <div className="flex items-center justify-between gap-4">
                    <div className="flex items-center gap-4">
                        <div className="flex-shrink-0">
                            <img src={ProfileTiny} alt="" />
                        </div>
                        <h6 className='!text-12 whitespace-nowrap font-500  transition-all duration-[300ms]  group-[.bar-mini]:opacity-0 group-[.bar-mini]:invisible'>Mazen ALfaifi</h6>
                    </div>
                    <div className="social-icons flex items-center gap-3">
                        <Link to={""} className='group/link'>
                            <span><IconNotification className="w-5 h-5 text-white  transition-colors duration-[300ms]  group-hover/link:text-danger-300" /></span>
                        </Link>
                        <Link to={""} className='group/link'>
                            <span><IconChat2 className="w-5 h-5 text-white transition-colors duration-[300ms]  group-hover/link:text-danger-300" /></span>
                        </Link>
                        <Link to={""} className='group/link'>
                            <span><IconSetting className="w-5 h-5 text-danger-600 transition-colors duration-[300ms]  group-hover/link:text-danger-300" /></span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardSidebar
