import axios from "axios";
import { API_HOST } from "../constants/ServiceConstant";
import { errorHandler } from "../util/util";

const userInfo = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_HOST}/student/users/info`)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const userUpdate = async (userUpdateFormData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_HOST}/student/users/update`, userUpdateFormData)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const UserService = {
  userInfo,
  userUpdate
};

export default UserService;
