import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { cn } from '../../lib/utils'
import IconArrowDown from '../svg-components/IconArrowDown'
import ArrowDownIcon from '../icons/ArrowDownIcon'

const CustomeSelect = ({ data, buttonClass, lable, lableClass, listButtonTextClass,dropIconClass, listButtonRootClass, listDropRootClass, listDropTextClass, prefixicon }) => {
    const [selected, setSelected] = useState(data && data[0])

    return (
        <div className='flex flex-col'>
            <Listbox value={selected} onChange={setSelected}>
                <div className="relative">
                    <Listbox.Button className={cn(`relative w-full cursor-pointer bg-dark-950 rounded-sm py-[14px] px-4 text-left`, listButtonRootClass)}>
                        <div className="flex items-center gap-3">
                            {prefixicon && <span>{prefixicon}</span>}
                            <span className={cn("block truncate !text-14 font-500 text-dark-950 dark:text-white", listButtonTextClass)}>{selected}</span>
                        </div>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                            <ArrowDownIcon className={cn("w-4 h-4 text-white",dropIconClass)} />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className={cn("absolute mt-2 z-20 w-full max-h-[200px] px-[14px] py-3.5 bg-light bg-dark-600 rounded-sm cursor-pointer", listDropRootClass)}>
                            {data?.map((value, index) => (
                                <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                        `relative select-none px-[0px] py-2 cursor-pointer`
                                    }
                                    value={value}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={cn(`block truncate !text-14 font-500  ${selected ? 'text-white' : 'text-gray-300'}`, listDropTextClass)}
                                            >
                                                {value}
                                            </span>
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

export default CustomeSelect