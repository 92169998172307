import { cva } from 'class-variance-authority'
import React from 'react'
import { cn } from '../../lib/utils'
const iconButtonVariants = cva(
    'rounded-sm flex items-center justify-center flex-shrink-0',
    {
        variants: {
            variant: {
                default: 'hover:text-yellow-600 text-danger-200',
            },
            size:{
                 default:'w-[40px] h-[40px]',
                 54:'w-[54px] h-[54px]',
                 60:'w-[60px] h-[60px]',
                 80:'md:w-[80px] md:h-[80px] w-[54px] h-[54px]',
            },
        },
        defaultVariants:{
            variant:'default',
            size:'default'
        }

    }
)
const IconButton = ({ children, className, variant, size ,icon,...props}) => {
    return (
        <button className={cn(iconButtonVariants({ variant, size, className}))} {...props}>
            {children || icon}
        </button>
    )
}

export { IconButton , iconButtonVariants}