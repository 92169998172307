import React from 'react'

const IconBoldArrow = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <path d="M16.9164 1.70041L31.3362 16.1202C31.8908 16.6748 32.3067 17.2756 32.584 17.9226C32.8613 18.5697 33 19.2629 33 20.0024C33 20.7419 32.8613 21.4352 32.584 22.0822C32.3067 22.7292 31.8908 23.3301 31.3362 23.8847L16.9164 38.3044C15.1602 40.0607 13.1488 40.4526 10.8823 39.4802C8.61581 38.5078 7.48441 36.7756 7.48811 34.2835L7.48811 5.72131C7.48811 3.22558 8.62135 1.49151 10.8878 0.519103C13.1543 -0.453307 15.1639 -0.0595397 16.9164 1.70041Z" fill="currentcolor" />
        </svg>
    )
}

export default IconBoldArrow
