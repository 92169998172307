import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth.js";
import cartReducer from "./features/cart.js";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (applyMiddleware) =>
    applyMiddleware({
      serializableCheck: false,
    }),
});

persistStore(store);

export default store;
