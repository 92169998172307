import axios from "axios";
import { API_HOST } from "../constants/ServiceConstant";
import { errorHandler } from "../util/util";

const cartList = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_HOST}/student/cart/list`)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const cartAdd = (courseId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_HOST}/student/cart/add/${courseId}`)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const cartRemove = (courseId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_HOST}/student/cart/remove/${courseId}`)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const cartClear = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_HOST}/student/cart/clear`)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const cartAddCoupon = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_HOST}/student/cart/add_coupon`)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};  

const cartDeleteCoupon = (coupon) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_HOST}/student/cart/delete_coupon/${coupon}`)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const cartConfirm = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_HOST}/student/cart/confirm`)
      .then(function (res) {
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err, false);
        return reject(data);
      });
  });
};

const CartService = {
  cartList,
  cartAdd,
  cartRemove,
  cartClear,
  cartAddCoupon,
  cartDeleteCoupon,
  cartConfirm
};

export default CartService;
