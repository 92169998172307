import React from 'react'
import { Link } from 'react-router-dom'
import IconTrash from '../svg-components/IconTrash'
import Product1 from "../../assets/images/home/courses-items-1.jpg";
import { IconButton } from '../ui/IconButton';

const CartProductListItem = () => {
    return (
        <li className='pb-4 border-b border-gray-700 last:border-0  last:pb-0 last:mb-0  mb-4'>
            <div className="cart-product-card flex items-start gap-4">
                <div className="">
                    <div className="img-wrapper w-[60px] h-[60px] md:w-[80px] md:h-[80px] rounded-sm overflow-hidden mb-1">
                        <img src={Product1} alt="" className='w-full h-full  object-cover object-center' />
                    </div>

                </div>
                <div className="text-content">
                    <div className="flex items-start gap-4 mb-2">
                        <h6 className='text-black !text-base line-clamp-2 flex-grow'>Malware Analysis and Reverse Engineering</h6>
                        <IconButton className="text-dark-850 w-auto h-auto hover:text-danger-650">
                            <IconTrash className="w-5 h-5" />
                        </IconButton>
                    </div>
                    <div className="flex items-start justify-between gap-4">
                        <div className="">
                            <p className='text-sm text-black font-medium flex-grow mb-0'>By Mazen Alfaifi</p>
                            <Link className='text-14 xsm:text-16 text-danger-950 font-600 flex-grow underline'>show the course</Link>
                        </div>
                        <span className='flex-shrink-0 text-16 font-700 text-danger-950'>799$</span>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default CartProductListItem