import React from 'react'

export default function LinkIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 19 13" fill="none">
            <path d="M4.32101 13C3.17042 13.0036 2.06534 12.5688 1.24842 11.7908C0.419079 10.9449 -0.0290344 9.81892 0.00146041 8.65757C0.0319552 7.49621 0.538594 6.39334 1.41128 5.5886L5.49359 1.63794C6.38093 0.758513 7.59508 0.252504 8.87018 0.230713C9.44295 0.234378 10.0093 0.346786 10.5366 0.561464C11.064 0.776142 11.5418 1.08885 11.9428 1.48158C12.7834 2.33247 13.2383 3.46867 13.2078 4.64107C13.1773 5.81348 12.6638 6.92643 11.7799 7.73593L10.4119 9.04934C10.3374 9.12615 10.2475 9.18776 10.1476 9.23049C10.0477 9.27322 9.93987 9.2962 9.83053 9.29805C9.7212 9.2999 9.61259 9.28059 9.5112 9.24127C9.4098 9.20195 9.3177 9.14343 9.24037 9.06919C9.16305 8.99495 9.10209 8.90652 9.06114 8.80917C9.02018 8.71183 9.00007 8.60756 9.002 8.50259C9.00393 8.39761 9.02786 8.29409 9.07237 8.19819C9.11687 8.10229 9.18104 8.01598 9.26104 7.94441L10.5856 6.5893C11.1553 6.076 11.4909 5.36752 11.5193 4.61793C11.5478 3.86834 11.2669 3.13828 10.7376 2.58652C10.1591 2.10212 9.40945 1.84847 8.64239 1.87754C7.87533 1.90661 7.14889 2.2162 6.61189 2.74288L2.56214 6.68311C2.00313 7.19519 1.67356 7.89599 1.64317 8.63721C1.61277 9.37844 1.88394 10.1019 2.39929 10.6546C2.68252 10.9234 3.02388 11.129 3.39915 11.2569C3.77442 11.3848 4.1744 11.4318 4.57073 11.3947C4.67632 11.3847 4.78295 11.3948 4.88444 11.4245C4.98594 11.4543 5.08029 11.503 5.16206 11.5679C5.24382 11.6329 5.31138 11.7127 5.36083 11.8029C5.41028 11.893 5.44064 11.9917 5.45016 12.0931C5.4714 12.299 5.40675 12.5046 5.2704 12.6648C5.13405 12.825 4.93711 12.9268 4.72273 12.9479L4.32101 13Z" fill="currentcolor" />
            <path d="M10.1301 12.7394C9.55731 12.7357 8.99096 12.6233 8.46363 12.4086C7.93631 12.194 7.45842 11.8813 7.05749 11.4885C6.21685 10.6376 5.76193 9.50144 5.79246 8.32903C5.82299 7.15663 6.33647 6.04367 7.22034 5.23418L8.58835 3.92076C8.6629 3.84395 8.7528 3.78235 8.85268 3.73962C8.95257 3.69689 9.0604 3.67391 9.16973 3.67206C9.27907 3.67021 9.38767 3.68952 9.48906 3.72884C9.59046 3.76816 9.68256 3.82668 9.75989 3.90092C9.83721 3.97516 9.89817 4.06359 9.93912 4.16093C9.98008 4.25828 10.0002 4.36255 9.99826 4.46752C9.99633 4.57249 9.9724 4.67602 9.9279 4.77192C9.88339 4.86782 9.81922 4.95413 9.73922 5.0257L8.41464 6.38081C7.84495 6.89411 7.50939 7.60259 7.48093 8.35218C7.45246 9.10176 7.7334 9.83183 8.26264 10.3836C8.84121 10.868 9.59081 11.1216 10.3579 11.0926C11.1249 11.0635 11.8514 10.7539 12.3884 10.2272L16.4815 6.28699C17.0345 5.76937 17.3561 5.06559 17.3784 4.32444C17.4007 3.58329 17.1219 2.86287 16.601 2.31548C16.3177 2.04674 15.9764 1.84113 15.6011 1.71323C15.2258 1.58533 14.8259 1.53827 14.4295 1.57538C14.3239 1.58543 14.2173 1.5753 14.1158 1.54557C14.0143 1.51583 13.92 1.46709 13.8382 1.40216C13.7564 1.33722 13.6889 1.25738 13.6394 1.16724C13.59 1.0771 13.5596 0.978449 13.5501 0.876982C13.5289 0.671089 13.5935 0.465512 13.7299 0.305294C13.8662 0.145076 14.0632 0.0432838 14.2775 0.0222213C14.9095 -0.0406753 15.5482 0.0303419 16.1485 0.230263C16.7488 0.430185 17.2962 0.75414 17.7518 1.17928C18.5812 2.0252 19.0293 3.15119 18.9988 4.31254C18.9683 5.4739 18.4617 6.57677 17.589 7.3815L13.5067 11.3322C12.6193 12.2116 11.4052 12.7176 10.1301 12.7394Z" fill="currentcolor" />
        </svg>
    )
}
