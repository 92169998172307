import React from 'react'

const Backdrop = ({ setToogleSidebar, setCartDrawerOpen, cartDrawerOpen ,toogleSidebar}) => {
  const handleClick = ()=>{
     if(toogleSidebar === true){
      setToogleSidebar(false)
     }
     if(cartDrawerOpen === true){
      setCartDrawerOpen(false)
     }
  }
  return (
    <div className='fixed h-screen w-full z-30 bg-black/50 cursor-pointer' onClick={handleClick}>
    </div>
  )
}

export default Backdrop