import React, { useState } from 'react'
import IconLock from '../svg-components/IconLock';
import { cn } from '../../lib/utils';
import IconEyeClose from '../svg-components/IconEyeClose';
import IconEye from '../svg-components/IconEye';
import ErrorMessagePara from '../common/ErrorMessage';

const InputPassword = ({ suffixIcon, prefixIcon, size, inputClassName, className, iconClassName, type, placeholder, groupClassName, disabled, suffixWrap, prefixWrap, errorType, ...props }) => {
    const [togglePassword, settogglePassword] = useState(false);
    return (
        <div className={`form-group mb-4 lg:mb-6 ${groupClassName ? groupClassName : ""}`}>
            <div className={cn(`flex gap-3 border  border-white py-3 px-4 rounded-sm items-center w-full required:border-danger-950 invalid:border-danger-950 ${disabled ? "opacity-50" : ""}`, className)}>
                <span><IconLock className="w-5 h-5 text-gray-700" /></span>
                <input type={togglePassword ? "text" : "password"} className={cn(`peer w-full !text-white text-16 font-400 placeholder:text-gray-300 bg-transparent border-0 outline-none`, inputClassName)} placeholder={placeholder ? placeholder : ""} {...props} />
                <button aria-label="button" type="button" onClick={() => settogglePassword(!togglePassword)}>{!togglePassword ? <IconEyeClose className="w-5 h-5 text-gray-700 " /> : <IconEye className="w-5 h-5 text-gray-700" />}</button>
            </div>
            <ErrorMessagePara errorType={errorType} />
        </div>
    )
}

export default InputPassword