import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import CartDrawer from "../components/common/CartDrawer";
import Backdrop from "../components/common/Backdrop";
import AOS from 'aos';
import 'aos/dist/aos.css';

const MainLayout = ({ children }) => {
  const [toogleSidebar, setToogleSidebar] = useState(false);
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      {
        (toogleSidebar || cartDrawerOpen) && <Backdrop setCartDrawerOpen={setCartDrawerOpen} cartDrawerOpen={cartDrawerOpen} setToogleSidebar={setToogleSidebar} toogleSidebar={toogleSidebar} />
      }
      <Header setToogleSidebar={setToogleSidebar} toogleSidebar={toogleSidebar} setCartDrawerOpen={setCartDrawerOpen} cartDrawerOpen={cartDrawerOpen} />
      <CartDrawer setCartDrawerOpen={setCartDrawerOpen} cartDrawerOpen={cartDrawerOpen} />
      <main className="mt-[64px] sm:mt-[84px]">{children}</main>
      <Footer />
    </>
  );
};

export default MainLayout;
