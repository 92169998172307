import React from 'react'

const IconChat2 = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" >
            <g clip-path="url(#clip0_1609_167)">
                <path d="M19.7303 0C19.7196 0.0107172 19.6982 0.0107172 19.666 0.0107172C8.82024 0.0107172 0 8.83096 0 19.6767C0 24.1029 1.51112 28.4005 4.27616 31.8622L1.41467 38.5068C1.17889 39.0534 1.4361 39.6857 1.97196 39.9108C2.16487 39.9965 2.37921 40.018 2.58284 39.9858L13.075 38.1425C15.1755 38.9034 17.3833 39.2892 19.6124 39.2785C30.4582 39.2785 39.2785 30.4582 39.2785 19.6124C39.2999 8.79881 30.544 0.0107172 19.7303 0ZM19.6232 37.1458C17.5547 37.1458 15.5078 36.7707 13.568 36.0419C13.3858 35.9669 13.1929 35.9561 12.9999 35.9883L4.16898 37.5316L6.50533 32.098C6.66609 31.7229 6.60178 31.2835 6.33385 30.9727C5.06923 29.5044 4.06181 27.8325 3.35448 26.0213C2.56141 23.9958 2.15415 21.8416 2.15415 19.666C2.15415 9.99913 10.0206 2.14344 19.6767 2.14344C29.3115 2.122 37.135 9.91339 37.1458 19.5481V19.6124C37.1458 29.2901 29.2793 37.1458 19.6232 37.1458Z" fill="currentcolor" />
                <path d="M18.7877 15.722H13.0004C12.411 15.722 11.9287 16.2043 11.9287 16.7938C11.9287 17.3832 12.411 17.8655 13.0004 17.8655H18.7877C19.3772 17.8655 19.8594 17.3832 19.8594 16.7938C19.8594 16.2043 19.3772 15.722 18.7877 15.722ZM26.2897 21.0806H13.0004C12.411 21.0806 11.9287 21.5629 11.9287 22.1524C11.9287 22.7418 12.411 23.2241 13.0004 23.2241H26.2897C26.8792 23.2241 27.3615 22.7418 27.3615 22.1524C27.3615 21.5629 26.8792 21.0806 26.2897 21.0806Z" fill="currentcolor" />
            </g>
            <defs>
                <clipPath id="clip0_1609_167">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconChat2
