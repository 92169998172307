import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import { userToken } from "../util/util";

const initialState = {
  isAuthenticated: false,
  user: null,
  requestStatus: null,
  registerStatus: null,
  loginStatus: null,
  pwdResetStatus: null,
  logoutStatus: null,
  commonMessage: null,
  token:null,
};

export const studentRegister = createAsyncThunk(
  "auth/student-register",
  async ({ formData }, thunkAPI) => {
    try {
      const data = await AuthService.studentRegister(formData);
      await userToken();
      const userData = await UserService.userInfo();
      const userNewData = {
        ...userData.data.data.user,
        total_scores: null,
        created: null,
        role: null,
        password: null,
        message: userData.data.message,
        id: 0,
      };
      return { user: userNewData };
    } catch (error) {
      console.log({ error });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const studentLogin = createAsyncThunk(
  "auth/student-login",
  async (formData, thunkAPI) => {
    try {
           // console.log("studentLogin api called");
      const loginData = await AuthService.studentLogin(formData);
           console.log({loginData});
      await userToken();
      const userData = await UserService.userInfo();
      const userNewData = {
        ...userData.data.data.user,
        total_scores: null,
        created: null,
        role: null,
        password: null,
        message: userData.data.message,
        id: 0,
      };
      return { user: userNewData };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const studentInfo = createAsyncThunk(
  "auth/student-info",
  async (args,thunkAPI) => {
    try {
      const info = await UserService.userInfo();
      return { user: info.data.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const studentLogout = createAsyncThunk(
  "auth/student-logout",
  async (thunkAPI) => {
    try {
      const logoutData = await AuthService.studentLogout();
      return { user: logoutData.data };
    } catch (error) {
      console.log({ error });
      return thunkAPI.rejectWithValue();
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(studentRegister.pending, (state, action) => {
        // state.message = true;
        state.user = action.payload.user;
      })
      .addCase(studentRegister.rejected, (state, action) => {
        // state.message = false;
        state.user = null;
      })
      .addCase(studentRegister.fulfilled, (state, action) => {
        // state.message = false;
        state.user = null;
        state.cart = null;
      })
      .addCase(studentLogin.pending, (state, action) => {
        state.requestStatus = 0;
        state.loginStatus = false;
        state.user = null;
      })
      .addCase(studentLogin.fulfilled, (state, action) => {
        //console.log({ action });
        state.isAuthenticated = true;
        state.requestStatus = 1;
        state.loginStatus = true;
        state.user = action?.payload?.user;
        state.commonMessage = action?.payload?.message;
      })
      .addCase(studentLogin.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.requestStatus = 2;
        state.loginStatus = false;
        state.user = null;
        state.cart = null;
        state.commonMessage = action?.payload?.message;
      })
      .addCase(studentLogout.fulfilled, (state, action) => {
        state.isAuthenticated = false;
        state.requestStatus = 1;
        state.loginStatus = false;
        state.user = null;
        state.cart = null;
        state.commonMessage = action?.payload?.message;
      })
      .addCase(studentInfo.fulfilled, (state, action) => {
         state.user = action?.payload?.user;
      })
      .addCase(studentInfo.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.requestStatus = 2;
        state.loginStatus = false;
        state.user = null;
        state.cart = null;
        state.commonMessage = action?.payload?.message;
      });
  },
});

const { reducer } = authSlice;
export default reducer;
