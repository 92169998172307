export const createPath = (root, sublink) => `${root}/${sublink}`;

const ROOT_PATH = "/";
const SIGN_IN = "/signin";

export const PATHS = {
  root: ROOT_PATH,
  signIn: SIGN_IN,
};
export const PATH_HOME = {
  root: ROOT_PATH,
};
export const PATH_ABOUT = {
  root: "about",
};
export const PATH_COURSES = {
  root: "courses",
};
export const PATH_INSTRUCTORS = {
  root: "instructors",
};
export const PATH_PRICING = {
  root: "pricing",
};
export const PATH_FEEDBACK = {
  root: "feedback",
};
export const PATH_LEADERBOARD = {
  root: "leaderboard",
};
