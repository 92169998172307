import React from 'react'

const IconArrowDown = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <path d="M20.001 28.4707L40 15.1353L35.2996 12.0001L19.999 22.1789L4.7004 12.0001L1.04947e-07 15.1338L20.001 28.4707Z" fill="currentcolor" />
        </svg>
    )
}

export default IconArrowDown