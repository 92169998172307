import React from 'react'

const IconForm = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <g clip-path="url(#clip0_1619_211)">
                <path d="M9.99984 36.6668C9.08317 36.6668 8.29817 36.3402 7.64484 35.6868C6.99151 35.0335 6.6654 34.2491 6.66651 33.3335V6.66683C6.66651 5.75017 6.99317 4.96517 7.64651 4.31183C8.29984 3.6585 9.08428 3.33239 9.99984 3.3335H23.3332L33.3332 13.3335V18.5002L19.9998 31.7918V36.6668H9.99984ZM23.3332 36.6668V33.1252L31.9165 24.5002L35.4998 28.0002L26.8748 36.6668H23.3332ZM36.7082 26.8335L33.1665 23.2918L34.3332 22.1252C34.6665 21.7918 35.0693 21.6252 35.5415 21.6252C36.0137 21.6252 36.4026 21.7918 36.7082 22.1252L37.8748 23.3335C38.1804 23.6668 38.3332 24.0629 38.3332 24.5218C38.3332 24.9807 38.1804 25.3624 37.8748 25.6668L36.7082 26.8335ZM21.6665 15.0002H29.9998L21.6665 6.66683V15.0002Z" fill="currentcolor" />
            </g>
            <defs>
                <clipPath id="clip0_1619_211">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconForm
