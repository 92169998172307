import React from 'react'

const IconJoin = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" >
            <path d="M14.7857 19.2857C8.275 19.2857 3 24.5607 3 31.0714V35.3571C3 37.1193 4.45214 38.5714 6.21429 38.5714H19.0714C19.3556 38.5714 19.6281 38.4585 19.829 38.2576C20.03 38.0567 20.1429 37.7842 20.1429 37.5C20.1429 37.2158 20.03 36.9433 19.829 36.7424C19.6281 36.5415 19.3556 36.4286 19.0714 36.4286H6.21429C5.60214 36.4286 5.14286 35.9693 5.14286 35.3571V31.0714C5.14286 25.7107 9.425 21.4286 14.7857 21.4286H24.0714C25.0321 21.4286 25.9557 21.5671 26.8264 21.825C26.9617 21.8659 27.1036 21.8797 27.2442 21.8655C27.3848 21.8513 27.5211 21.8094 27.6455 21.7423C27.7698 21.6752 27.8796 21.5842 27.9686 21.4745C28.0577 21.3648 28.1241 21.2385 28.1641 21.103C28.2041 20.9675 28.217 20.8255 28.2019 20.685C28.1868 20.5445 28.1441 20.4084 28.0762 20.2845C28.0083 20.1606 27.9165 20.0514 27.8063 19.9631C27.696 19.8748 27.5693 19.8092 27.4336 19.77C26.3419 19.448 25.2096 19.2849 24.0714 19.2857H14.7857ZM19.4286 0C14.7071 0 10.8571 3.85 10.8571 8.57143C10.8571 13.2929 14.7071 17.1429 19.4286 17.1429C24.15 17.1429 28 13.2929 28 8.57143C28 3.85 24.15 0 19.4286 0ZM19.4286 2.14286C22.9914 2.14286 25.8571 5.00857 25.8571 8.57143C25.8571 12.1343 22.9914 15 19.4286 15C15.8657 15 13 12.1343 13 8.57143C13 5.00857 15.8657 2.14286 19.4286 2.14286Z" fill="currentcolor" />
            <path d="M28.7143 22.8572C23.9929 22.8572 20.1429 26.7072 20.1429 31.4286C20.1429 36.15 23.9929 40 28.7143 40C30.2805 40.0003 31.8168 39.5714 33.1564 38.76C33.3996 38.6127 33.5742 38.3749 33.642 38.0988C33.7098 37.8227 33.6651 37.531 33.5179 37.2879C33.3706 37.0447 33.1327 36.8701 32.8566 36.8023C32.5806 36.7345 32.2889 36.7792 32.0457 36.9265C31.0411 37.5352 29.8889 37.8571 28.7143 37.8572C25.1514 37.8572 22.2857 34.9915 22.2857 31.4286C22.2857 27.8657 25.1514 25 28.7143 25C32.2771 25 35.1429 27.8657 35.1429 31.4286C35.1429 32.6029 34.8214 33.7557 34.2121 34.76C34.0648 35.0032 34.0202 35.2949 34.088 35.571C34.1557 35.847 34.3304 36.0849 34.5736 36.2322C34.8167 36.3795 35.1084 36.4241 35.3845 36.3564C35.6606 36.2886 35.8984 36.1139 36.0457 35.8707C36.857 34.5311 37.2859 32.9948 37.2857 31.4286C37.2857 26.7072 33.4357 22.8572 28.7143 22.8572Z" fill="currentcolor" />
            <path d="M28.7143 27.8572C28.1207 27.8572 27.6429 28.335 27.6429 28.9286V30.3572H26.2143C25.6207 30.3572 25.1429 30.835 25.1429 31.4286C25.1429 32.0222 25.6207 32.5 26.2143 32.5H27.6429V33.9286C27.6429 34.5215 28.1207 35 28.7143 35C29.3079 35 29.7857 34.5215 29.7857 33.9286V32.5H31.2143C31.8079 32.5 32.2857 32.0215 32.2857 31.4286C32.2857 30.8357 31.8079 30.3572 31.2143 30.3572H29.7857V28.9286C29.7857 28.335 29.3079 27.8572 28.7143 27.8572Z" fill="currentcolor" />
        </svg>
    )
}

export default IconJoin