export default function ErrorMessagePara({ errorType, customMessage }) {

    const getMessageByType = (eType) => {
        switch (eType) {
            case 'required':
                return "This field is required";

            case 'min_6':
                return "Minimum 6 characters are required";

            case 'max_6':
                return "Maximum 6 characters are allowed";

            case 'min_24':
                return "Minimum 24 characters are required";

            case 'phone_no':
                return "Please enter a valid phone number";

            case 'pincode':
                return "Please enter a valid Zip Code";

            case 'min_4':
                return "Minimum 4 characters are required";

            case 'min_3':
                return "Minimum 3 characters are allowed";

            case 'max_20':
                return "Maximum 20 characters are allowed";

            case 'min_15':
                return "Minimum 15 characters are required";

            case 'max_15':
                return "Maximum 15 characters are allowed";

            case 'valid_email':
                return "The field must be a valid email";

            case 'min_7':
                return "Minimum 7 characters are required";

            case 'min_80':
                return "The field must be 80 or more";

            case 'max_300':
                return "The field must be 300 or less";

            case 'password_blank_space_not_allowed':
                return "Blank spaces are not allowed in the password";

            case 'phone_number_invalid':
                return "The phone number is invalid";

            case 'password_checker_check':
                return "Please check Password Checker Error";

            case 'pattern':
                return "The field format is invalid.";

            default:
                return "";
        }
    }

    return (<>
        {errorType && errorType !== '' ?
            <p className="error-label text-start mt-1 peer-invalid:block text-danger-200 text-14">{getMessageByType(errorType)}</p> :
            <p className="error-label text-start mt-1 hidden peer-invalid:block text-danger-200 text-14">{customMessage}</p>}
    </>)
}
