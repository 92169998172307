import React, { useEffect, useState } from "react";
// import Logo from "../components/svg-components/Logo";
import { Link, NavLink } from "react-router-dom";
import i18n from "i18next";
import { connect } from "react-redux";
import {
  PATH_ABOUT,
  PATH_COURSES,
  PATH_FEEDBACK,
  PATH_HOME,
  PATH_INSTRUCTORS,
  PATH_LEADERBOARD,
  PATH_PRICING,
} from "../routes/path";
import ChangeLanguageICN from "../components/svg-components/ChangeLanguageICN";
import { withTranslation } from "react-i18next";
import MenuICN from "../components/svg-components/MenuICN";
import CloseICN from "../components/svg-components/CloseICN";
import LoginICN from "../components/svg-components/LoginICN";
import { studentInfo, studentLogin, studentLogout } from "../features/auth";
import { useToast } from "../providers/toast/ToastService";
import LoginModal from "../components/modals/LoginModal";
import RegisterModal from "../components/modals/RegisterModal";
import ResetPasswordModal from "../components/modals/ResetPasswordModal";
import NewPassword from "../components/modals/NewPassword";
import Logo from "../assets/images/Logo.svg";
import IconJoin from "../components/svg-components/IconJoin";
import IconLogin from "../components/svg-components/IconLogin";
import IconClose from "../components/svg-components/IconClose";
import IconCart from "../components/svg-components/IconCart";
import Profile from "../assets/images/profile-img.jpeg";
import IconArrowDown from "../components/svg-components/IconArrowDown";

const Header = (props) => {
  const { setToogleSidebar, toogleSidebar, setCartDrawerOpen, cartDrawerOpen } = props
  const toast = useToast();
  const [isSticky, setIsSticky] = useState(false);
  const [isResponsiveMenuOpen, setIsResponsiveMenuOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [newPasswordModalOpen, setNewPasswordModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (props.isAuthenticated == true) {
      props
        .dispatch(studentInfo())
        .unwrap()
        .then(() => { })
        .catch((error) => {
          //console.log(error);
          toast.error(error.message);
        });
    }
  }, []);

  const changeLanguage = (language) => {
    setToogleSidebar(false)
    document.dir = language === "ar" ? "rtl" : "ltr";
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    document.dir = i18n.language === "ar" ? "rtl" : "ltr";
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 120) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenResponsiveMenu = () => {
    setIsResponsiveMenuOpen(true);
  };

  const handleCloseResponsiveMenu = () => {
    setIsResponsiveMenuOpen(false);
  };

  // useEffect(() => {
  //   const headerMenu = document.getElementsByClassName("header_menu")[0];
  //   const responsiveMenuOpenBtn = document.getElementsByClassName(
  //     "responsive_menu_open_btn"
  //   )[0];
  //   const handleClickOutside = (event) => {
  //     if (
  //       isResponsiveMenuOpen &&
  //       !headerMenu.contains(event.target) &&
  //       !responsiveMenuOpenBtn.contains(event.target)
  //     ) {
  //       setIsResponsiveMenuOpen(false);
  //     }
  //   };
  //   if (isResponsiveMenuOpen) {
  //     headerMenu.classList.add("show");
  //     document.body.style.overflow = "hidden";
  //     document.addEventListener("click", handleClickOutside);
  //   } else {
  //     headerMenu.classList.remove("show");
  //     document.body.style.overflow = "auto";
  //     document.removeEventListener("click", handleClickOutside);
  //   }
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [isResponsiveMenuOpen]);

  const loginStudent = async () => {
    // await logoutUser();
    props
      .dispatch(
        studentLogin({ email: "student@cyberna.com", password: "password123" })
      )
      .unwrap()
      .then((res) => {
        console.log({ res });
      })
      .catch(({ errorData, statusCode }) => {
        console.log({ statusCode });
        console.log({ errorData });
      });
  };

  const logoutUser = () => {
    props
      .dispatch(studentLogout())
      .unwrap()
      .then((res) => {
        toast.success(res.user.message);
      })
      .catch((logoutError) => {
        toast.error(logoutError.message);
      });
  };




  // mobile menu part
  const [widthSize, setWidthSize] = useState(0);

  let resizeWindow = () => {
    setWidthSize(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  let mobileClass = `fixed top-0 start-0 transition-transform ${toogleSidebar ? "translate-x-0" : "rtl:translate-x-[100%] -translate-x-[100%]"} z-[999] duration-300 bg-dark-950 h-screen w-full min-[390px]:max-w-[280px] transition-transform z-10 py-8 px-5 xl:p-0 overflow-y-auto`

  return (
    <>
      <header className="py-2.5 font-gilroy sm:min-h-[84px] fixed top-0 left-0 w-full z-50 bg-black">
        <div className="px-8 xxl:container">
          <div className="header-wrapper flex items-center justify-between ">
            <div className="logo-part">
              <div className="logo-wrapper">
                <Link to={"/"}>
                  <img src={Logo} alt="" className="max-w-[164px] sm:max-w-[222px]" />
                </Link>
              </div>
            </div>
            <div className={`menu-part  xl:block ${widthSize < 1200 ? mobileClass : ''}`}>
              <button className="absolute block xl:hidden top-3 ltr:right-3 rtl:left-3" onClick={() => setToogleSidebar(false)}>
                <IconClose className="text-white w-6 h-6" />
              </button>
              <ul className="flex items-start xl:items-center gap-6 flex-col xl:flex-row text-base font-500 text-white">
                <li>
                  <Link to={"/"} className="hover:text-danger-100">
                    {props.t("home")}
                  </Link>
                </li>
                <li>
                  <Link to={"/"} className="hover:text-danger-100">
                    {props.t("about")}
                  </Link>
                </li>
                <li>
                  <Link to={"/courses"} className="hover:text-danger-100">
                    {props.t("courses")}
                  </Link>
                </li>
                <li>
                  <Link to={"/"} className="hover:text-danger-100">
                    {props.t("instructors")}
                  </Link>
                </li>
                <li>
                  <Link to={"/"} className="hover:text-danger-100">
                    {props.t("pricing")}
                  </Link>
                </li>
                {
                  isAuthenticated == false ? <>
                    <li>
                      <Link className="text-16 xxl:text-18 font-500 text-white hover:text-danger-100">
                        {props.t("feedback")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/leaderboard" className="text-16 xxl:text-18 font-500 text-white hover:text-danger-100">
                        {props.t("leaderboard")}
                      </Link>
                    </li>
                  </>
                    : <li>
                      <Link to={"/"} className="hover:text-danger-100">
                        Labs
                      </Link>
                    </li>
                }

                <li className="sm:hidden block">
                  <button
                    onClick={() =>
                      changeLanguage(props.i18n.language === "en" ? "ar" : "en")
                    }
                    className="flex items-center gap-2 border border-white py-1 px-2"
                  >
                    {props.i18n.language === "en" ?
                      <>
                        <ChangeLanguageICN />
                        <span>{props.i18n.language === "en" ? `عربي` : "ENG "}</span>
                      </>
                      :
                      <>
                        <span>{props.i18n.language === "en" ? `عربي` : "ENG "}</span>
                        <ChangeLanguageICN />
                      </>
                    }
                  </button>
                </li>
              </ul>
            </div>
            <div className="action-part">
              {isAuthenticated == false ?
                <ul className="flex items-center gap-3 xsm:gap-4 xxl:gap-6">
                  <li className="sm:block hidden">
                    <button
                      onClick={() =>
                        changeLanguage(props.i18n.language === "en" ? "ar" : "en")
                      }
                      className="flex items-center gap-2 border border-white py-1 px-2 hover:border-danger-300"
                    >
                      {props.i18n.language === "en" ?
                        <>
                          <ChangeLanguageICN />
                          <span>{props.i18n.language === "en" ? `عربي` : "ENG "}</span>
                        </>
                        :
                        <>
                          <span>{props.i18n.language === "en" ? `عربي` : "ENG "}</span>
                          <ChangeLanguageICN />
                        </>
                      }
                    </button>
                  </li>
                  <li>
                    <button className="py-1 px-3 xxl:px-5 text-16 xxl:text-18  text-white border hidden md:block border-white rounded-md hover:border-danger-300" onClick={()=>setRegisterModalOpen(true)}>
                      {props.t("signin")}
                    </button>
                    <button className="w-[32px] h-[32px] min-[390px]:w-[36px] min-[390px]:h-[36px] sm:w-[42px] sm:h-[42px] rounded-full border flex items-center justify-center  md:hidden border-white" onClick={() => setLoginModalOpen(true)}>
                      <IconLogin className="w-[14px] h-[14px] xsm:w-4 xsm:h-4 sm:w-5 sm:h-5 text-white" />
                    </button>
                  </li>
                  <li>
                    <button className="py-1 px-3 xxl:px-5 text-16 xxl:text-18 hidden md:block border rounded-md bg-danger-950 border-danger-950" onClick={() => setNewPasswordModalOpen(true)}>
                      {props.t("join-now")}
                    </button>
                    <button className="w-[32px] h-[32px] min-[390px]:w-[36px] min-[390px]:h-[36px] sm:w-[42px] sm:h-[42px] rounded-full border flex items-center justify-center md:hidden border-white" onClick={() => setNewPasswordModalOpen(true)}>
                      <IconJoin className="w-[14px] h-[14px xsm:w-4 xsm:h-4 sm:w-5 sm:h-5 text-white" />
                    </button>
                  </li>
                  <li className="block xl:hidden leading-[1]">
                    <button onClick={() => setToogleSidebar(!toogleSidebar)}>
                      <span>
                        <MenuICN />
                      </span>
                    </button>
                  </li>
                </ul>
                : <ul className="flex items-center gap-3 xsm:gap-4 xxl:gap-6">
                  <li className="hidden sm:block">
                    <button className="py-1 px-4 border-s-4 border-danger-300 text-16 font-600" onClick={() =>
                      changeLanguage(props.i18n.language === "en" ? "ar" : "en")
                    }>
                      {
                        props.i18n.language === "en" ? `${props.i18n.language === "en" ? `AR` : "ENG "}` : `${props.i18n.language === "en" ? `AR` : "ENG "}`
                      }
                    </button>
                  </li>
                  <li className="order-3 leading-[1] md:order-2">
                    <div className="flex items-center gap-4">
                      <div className="avatar w-[42px] h-[42px] rounded-full overflow-hidden">
                        <img src={Profile} alt="" className="w-full h-full object-center object-cover" />
                      </div>
                      <h5 className="text-16 font-500 items-center gap-1 hidden md:flex">Mohammed H <span><IconArrowDown className="w-5 h-5 text-white" /> </span></h5>
                    </div>
                  </li>
                  <li className="order-2 leading-[1] md:order-3">
                    <button productNum={5} onClick={() => setCartDrawerOpen(!cartDrawerOpen)} className="relative before:content-[attr(productNum)] before:w-5 before:h-5 before:rounded-full before:bg-danger-950 before:absolute before:-top-2 before:text-[10px] before:font-600 before:text-white before:flex before:items-center before:justify-center before:-end-2">
                      <IconCart className="w-6 h-6 text-danger-300" />
                    </button>
                  </li>
                  <li className="block xl:hidden leading-[1] order-4">
                    <button onClick={() => setToogleSidebar(!toogleSidebar)}>
                      <span>
                        <MenuICN />
                      </span>
                    </button>
                  </li>
                </ul>
              }
            </div>
          </div>
        </div>
      </header>
      <LoginModal setLoginModalOpen={setLoginModalOpen} loginModalOpen={loginModalOpen} />
      <RegisterModal setRegisterModalOpen={setRegisterModalOpen} registerModalOpen={registerModalOpen} />
      <ResetPasswordModal setResetPasswordModalOpen={setResetPasswordModalOpen} resetPasswordModalOpen={resetPasswordModalOpen} />
      <NewPassword setNewPasswordModalOpen={setNewPasswordModalOpen} newPasswordModalOpen={newPasswordModalOpen} />
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(withTranslation("header")(Header));
