import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import IconArrowDown from '../svg-components/IconArrowDown'
import { Link } from 'react-router-dom'
import IconProfile from '../svg-components/IconProfile'
import IconForm from '../svg-components/IconForm'
import IconLogout from '../svg-components/IconLogout'

const ProfileDropdown = () => {
    return (
        <Menu as="div" className="relative font-gilroy">
            <Menu.Button aria-label="button" role="button" className="flex items-center justify-center gap-4">
                <div className='avatar w-[40px] h-[40px] rounded-full overflow-hidden flex-shrink-0'>
                    <img src={"https://images.pexels.com/photos/4473796/pexels-photo-4473796.jpeg?auto=compress&cs=tinysrgb&w=1600"} alt="" className='w-[100%] h-[100%] object-cover object-center' />
                </div>
                <h6 className='!text-14 lg:!text-16 font-500 whitespace-nowrap text-dark-950 dark:text-white sm:flex hidden items-center gap-3'>
                    Mazen Alfaifi <span><IconArrowDown className="w-4 h-4 text-white" /></span>
                </h6>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute shadow-dropdown z-30 dark:shadow-none right-0 mt-4 origin-top-right p-4 bg-black/90 text-white focus:outline-none  min-w-[190px] max-w-[190px] w-full before:w-4 before:h-4 before:bg-black before:absolute before:-top-[8px] before:rotate-45 before:end-[4px] before:z-10">
                    <Menu.Item>
                        {({ close }) => (
                            <ul className="hidden-scrollbar max-h-[350px] overflow-y-auto">
                                <li className='mb-4 last:mb-0 group'>
                                    <Link to={"/dashboard/profile"} onClick={close} className='flex items-center gap-4 text-16 font-500 text-white group-hover:text-red-600 transition-all duration-300'>
                                        <span><IconProfile className="w-5 h-5 group-hover:text-red-600 transition-all duration-300 " /> </span> Profile
                                    </Link>
                                </li>
                                <li className='mb-4 last:mb-0 group'>
                                    <Link to={"/dashboard"} onClick={close} className='flex items-center gap-4 text-16 font-500 text-white group-hover:text-red-600 transition-all duration-300'>
                                        <span><IconForm className="w-5 h-5 group-hover:text-red-600 transition-all duration-300" /> </span> Instructor Form
                                    </Link>
                                </li>
                                <li className='mb-4 last:mb-0 group'>
                                    <Link to={"/"} onClick={close} className='flex items-center gap-4 text-16 font-500 text-white group-hover:text-red-600 transition-all duration-300'>
                                        <span><IconLogout className="w-5 h-5 group-hover:text-red-600 transition-all duration-300" /> </span> sign out
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu >
    )
}

export default ProfileDropdown
