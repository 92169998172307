import axios from 'axios'

axios.defaults.withCredentials = true;

export function setBearerToken(token) {
    //console.log("token set");
    axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
    axios.defaults.headers.common['X-TOKEN'] = `${token}`;
    localStorage.setItem("token",token);
}
