import React from 'react'

const IconTrophy = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <g clip-path="url(#clip0_1609_45)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66667 1.11111C6.66667 0.816426 6.78373 0.533811 6.9921 0.325437C7.20048 0.117063 7.48309 0 7.77778 0H32.2222C32.5169 0 32.7995 0.117063 33.0079 0.325437C33.2163 0.533811 33.3333 0.816426 33.3333 1.11111V2.22222H38.8889C39.1836 2.22222 39.4662 2.33929 39.6746 2.54766C39.8829 2.75603 40 3.03865 40 3.33333V10C40 11.4734 39.4147 12.8865 38.3728 13.9284C37.3309 14.9702 35.9179 15.5556 34.4444 15.5556H32.5744C31.7803 17.7968 30.402 19.7853 28.582 21.3155C26.7621 22.8458 24.5665 23.8623 22.2222 24.26V31.1111H28.8889C29.1836 31.1111 29.4662 31.2282 29.6746 31.4365C29.8829 31.6449 30 31.9275 30 32.2222V38.8889C30 39.1836 29.8829 39.4662 29.6746 39.6746C29.4662 39.8829 29.1836 40 28.8889 40H11.1111C10.8164 40 10.5338 39.8829 10.3254 39.6746C10.1171 39.4662 10 39.1836 10 38.8889V32.2222C10 31.9275 10.1171 31.6449 10.3254 31.4365C10.5338 31.2282 10.8164 31.1111 11.1111 31.1111H17.7778V24.26C15.4335 23.8623 13.2379 22.8458 11.418 21.3155C9.59803 19.7853 8.21975 17.7968 7.42556 15.5556H5.55556C4.08213 15.5556 2.66905 14.9702 1.62718 13.9284C0.585316 12.8865 0 11.4734 0 10V3.33333C0 3.03865 0.117063 2.75603 0.325437 2.54766C0.533811 2.33929 0.816426 2.22222 1.11111 2.22222H6.66667V1.11111ZM33.3333 11.1111V4.44444H37.7778V10C37.7778 10.8841 37.4266 11.7319 36.8015 12.357C36.1763 12.9821 35.3285 13.3333 34.4444 13.3333H33.3333V11.1111ZM6.66667 4.44444H2.22222V10C2.22222 10.8841 2.57341 11.7319 3.19853 12.357C3.82365 12.9821 4.6715 13.3333 5.55556 13.3333H6.66667V4.44444Z" fill="currentcolor" />
            </g>
            <defs>
                <clipPath id="clip0_1609_45">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconTrophy
