import React, { useEffect, useState } from "react";
import Logo from "../components/svg-components/Logo";
import footerSocialWink from "../assets/images/footer_social_wink.png";
import footerSocialTwitter from "../assets/images/footer_social_twitter.png";
import footerSocialFacebook from "../assets/images/footer_social_facebook.png";
import footerSocialYT from "../assets/images/footer_social_yt.png";
import footerSocialInsta from "../assets/images/footer_social_insta.png";
import footerPayment1 from "../assets/images/footer_payment_card_1.png";
import footerPayment2 from "../assets/images/footer_payment_card_2.png";
import footerPayment3 from "../assets/images/footer_payment_card_3.png";
import footerPayment4 from "../assets/images/footer_payment_card_4.png";
import footerPayment5 from "../assets/images/footer_payment_card_5.png";
import { Link, NavLink } from "react-router-dom";
import { PATH_ABOUT } from "../routes/path";
import i18n from "i18next";
import { withTranslation } from "react-i18next";

const Footer = (props) => {
  const changeLanguage = (language) => {
    document.dir = language === 'ar' ? 'rtl' : 'ltr';
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    document.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, []);
  return (
    <footer className="footer_wrapper border-t border-dark-600  py-10 xl:py-16">
      <div className="container">
      <div className="grid grid-cols-1 xsm:grid-cols-2 lg:grid-cols-4 gap-6 xl:gap-10">
          <div className="widget1 xsm:col-span-2 mb-6 lg:mb-0">
            <div className="flex items-center flex-wrap justify-between gap-4 lg:gap-6 mb-6">
              <Link className="flex-shrink-0" to={"/"}><img src={require('../assets/images/Logo.svg').default} alt="footer social" /></Link>
              <div className="footer_social_container flex-shrink-0 flex items-center justify-center gap-6">
                <Link to="/"><img src={footerSocialWink} alt="footer social" /></Link>
                <Link to="/"><img src={footerSocialTwitter} alt="footer social" /></Link>
                <Link to="/"><img src={footerSocialFacebook} alt="footer social" /></Link>
                <Link to="/"><img src={footerSocialYT} alt="footer social" /></Link>
                <Link to="/"><img src={footerSocialInsta} alt="footer social" /></Link>
              </div>
            </div>
            <p className="text-base xxl:text-xl mb-6">{props.t("footer-content")}</p>
            <div className="footer_payment_container flex items-center gap-4">
              <Link className="" to="/"><img src={footerPayment1} alt="footer payment" /></Link>
              <Link className="" to="/"><img src={footerPayment2} alt="footer payment" /></Link>
              <Link className="" to="/"><img src={footerPayment3} alt="footer payment" /></Link>
              <Link className="" to="/"><img src={footerPayment4} alt="footer payment" /></Link>
              <Link className="" to="/"><img src={footerPayment5} alt="footer payment" /></Link>
            </div>
          </div>
          <div className="widget2 md:ps-4 lg:ps-8">
            <h3 className="mb-6 text-danger-200">{props.t("CYBERNA")}</h3>
            <ul className="text-lg flex flex-col gap-4">
              <li><Link to={"/"} className="hover:text-danger-200">{props.t("about-us")}</Link></li>
              <li><Link to={"/"} className="hover:text-danger-200">{props.t("terms-and-condition")}</Link></li>
              <li><Link to={"/"} className="hover:text-danger-200">{props.t("integrity-policy")}</Link></li>
            </ul>
          </div>
          <div className="widget3">
            <h3 className="mb-6 text-danger-200">{props.t("Important links")}</h3>
            <ul className="text-lg flex flex-col gap-4">
              <li><Link to={"/"} className="hover:text-danger-200">{props.t("all-courses")}</Link></li>
              <li><Link to={"/"} className="hover:text-danger-200">{props.t("what-did-they-say")}</Link></li>
              <li><Link to={"/"} className="hover:text-danger-200">{props.t("Leaderboards")}</Link></li>
              <li><Link to={"/"} className="hover:text-danger-200">{props.t("join-the-cyberna")}</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withTranslation("footer")(Footer);
