import React from 'react'

const IconLock = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <path d="M6 10V8c0-3.31 1-6 6-6s6 2.69 6 6v2M17 22H7c-4 0-5-1-5-5v-2c0-4 1-5 5-5h10c4 0 5 1 5 5v2c0 4-1 5-5 5Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.996 16h.01M11.995 16h.01M7.995 16h.008" stroke="currentcolor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconLock