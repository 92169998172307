import axios from "axios";
import { API_HOST } from "../constants/ServiceConstant";
import HTTP from "../security/Http";

export const errorHandler = (err) => {
  const statusCode = err.response?.status ?? 0;
  const data = {
    errorData: "",
    statusCode,
    message: "",
  };

  if (
    err.response &&
    err.response.data &&
    err.response.data.message &&
    statusCode != 401
  ) {
    // ToastMe(err.response.data.message, 'error');
  }

  if (statusCode > 200) {
    data.errorData =
      err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : "";
    data.message =
      err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : "";
    data.statusCode = statusCode;
  }
  return data;
};

export const userToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_HOST}/student/users/token`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(function (res) {
        HTTP.setBearerToken(res?.data?.data?.token);
        localStorage.setItem('token',res?.data?.data?.token)
        return resolve(res);
      })
      .catch(function (err) {
        const data = errorHandler(err);
        return reject(data);
      });
  });
};
