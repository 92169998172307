import React from 'react'
import IconClose from '../svg-components/IconClose'
import CartProductListItem from './CartProductListItem'
import { Button } from '../ui/Button'
import IconLongArrow from '../svg-components/IconLongArrow'
import { IconButton } from '../ui/IconButton'

const CartDrawer = ({ cartDrawerOpen, setCartDrawerOpen }) => {
    return (
        <div className={`w-full xsm:w-[380px] fixed top-0 ${cartDrawerOpen ? "end-0" : "-end-full"} transition-transform duration-300 h-[100vh] font-gilroy bg-gray-900 z-[999]`}>
            <div className="cart-header bg-red-950 px-4 py-2.5 flex items-center">
                <h5 className='text-lg capitalize font-semibold flex-grow'>cart</h5>
                <IconButton className='w-auto h-auto bg-none' onClick={() => setCartDrawerOpen(false)}>
                    <IconClose className="w-6 h-6 text-white" />
                </IconButton>
            </div>
            <div className="cart-body h-[calc(100%-48px)] overflow-hidden">
                <div className="cart-product-list mb-6 h-[calc(100%-290px)] xsm:h-[calc(100%-290px)] md:h-[calc(100%-280px)] theme-scrollbar overflow-y-auto px-4 pt-6">
                    <ul className='h-full'>
                        {
                            [...Array(8)].map((item, index) => {
                                return <CartProductListItem key={index} />
                            })
                        }
                    </ul>
                </div>
                <div className="">
                    <div className="amount-calculation-table mb-4 px-4">
                        <table className='w-full'>
                            <tbody>
                                <tr>
                                    <td className='text-black text-sm md:text-16 font-semibold py-1'>Item total price (VAT excluded)  </td>
                                    <td className='text-danger-950 text-sm md:text-16 font-semibold py-1 text-end'>1,438$</td>
                                </tr>
                                <tr>
                                    <td className='text-black text-sm md:text-16 font-semibold py-1'>Tax</td>
                                    <td className='text-danger-950 text-sm md:text-16 font-semibold py-1 text-end'> 215.7$</td>
                                </tr>
                                <tr>
                                    <td className='text-black text-sm md:text-16 font-semibold py-1 pb-2'>Discount</td>
                                    <td className='text-danger-950 text-sm md:text-16 font-semibold py-1 pb-2 text-end'>159.8$</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td className='text-black text-sm md:text-16 font-semibold py-[6px] border-t border-gray-700'>
                                        TOTAL PRICE (VAT included)
                                    </td>
                                    <td className='text-danger-950 text-sm md:text-16 font-semibold py-[6px] text-end border-t border-gray-700'>
                                        1,653.7$
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="cart-action flex flex-col  gap-4 px-4 pb-6">
                        <Button className="flex-grow border-transparent">
                            Proceed to checkout
                        </Button>
                        <Button className="flex-grow bg-transparent text-black  hover:text-white">
                            Continue shopping <span><IconLongArrow className="w-4 h-4 text-black group-hover/shop:text-white" /></span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartDrawer