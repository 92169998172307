import React from 'react'

const IconTrash = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <path d="M36.8903 10.1953C36.8531 10.1953 36.7973 10.1953 36.7414 10.1953C26.8996 9.20924 17.0764 8.83715 7.34617 9.82319L3.55083 10.1953C2.76944 10.2697 2.08107 9.71156 2.00666 8.93017C1.93224 8.14878 2.49038 7.47901 3.25316 7.4046L7.04849 7.03251C16.9462 6.02786 26.974 6.41855 37.0205 7.4046C37.7833 7.47901 38.3414 8.16738 38.267 8.93017C38.2112 9.65575 37.5972 10.1953 36.8903 10.1953Z" fill="currentcolor" />
            <path d="M13.636 8.31624C13.5616 8.31624 13.4871 8.31624 13.3941 8.29764C12.6499 8.16741 12.129 7.44183 12.2592 6.69764L12.6685 4.26045C12.9662 2.47441 13.3755 0 17.7103 0H22.5847C26.9382 0 27.3475 2.56743 27.6265 4.27905L28.0358 6.69764C28.1661 7.46043 27.6451 8.18601 26.901 8.29764C26.1382 8.42787 25.4126 7.90694 25.301 7.16276L24.8917 4.74416C24.6312 3.12557 24.5754 2.80929 22.6033 2.80929H17.7289C15.7569 2.80929 15.7197 3.06975 15.4406 4.72556L15.0127 7.14415C14.9011 7.83252 14.3057 8.31624 13.636 8.31624Z" fill="currentcolor" />
            <path d="M26.1201 39.9999H14.1759C7.68292 39.9999 7.42246 36.4093 7.21781 33.5069L6.00851 14.7721C5.9527 14.0093 6.54804 13.3396 7.31083 13.2837C8.09222 13.2465 8.74338 13.8233 8.7992 14.5861L10.0085 33.3209C10.2131 36.1488 10.2876 37.2093 14.1759 37.2093H26.1201C30.027 37.2093 30.1014 36.1488 30.2875 33.3209L31.4968 14.5861C31.5526 13.8233 32.2224 13.2465 32.9852 13.2837C33.7479 13.3396 34.3433 13.9907 34.2875 14.7721L33.0782 33.5069C32.8735 36.4093 32.6131 39.9999 26.1201 39.9999Z" fill="currentcolor" />
            <path d="M23.2352 29.7675H17.0399C16.2771 29.7675 15.6445 29.1349 15.6445 28.3721C15.6445 27.6093 16.2771 26.9768 17.0399 26.9768H23.2352C23.998 26.9768 24.6306 27.6093 24.6306 28.3721C24.6306 29.1349 23.998 29.7675 23.2352 29.7675Z" fill="currentcolor" />
            <path d="M24.7982 22.3253H15.4959C14.7331 22.3253 14.1006 21.6928 14.1006 20.93C14.1006 20.1672 14.7331 19.5346 15.4959 19.5346H24.7982C25.561 19.5346 26.1936 20.1672 26.1936 20.93C26.1936 21.6928 25.561 22.3253 24.7982 22.3253Z" fill="currentcolor" />
        </svg>
    )
}

export default IconTrash