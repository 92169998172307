import React from 'react'

const IconLongArrow = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <path d="M25.146 32.708L38 19.854L25.146 7" stroke="currentcolor" stroke-width="3.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 19.8539L37.6396 19.8539" stroke="currentcolor" stroke-width="3.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default IconLongArrow