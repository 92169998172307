import React from 'react'
import { cn } from '../../lib/utils'

const BasicCheck = ({id , label,lableClass}) => {
  return (
    <div className='checkbox flex items-center gap-3 group'>
         <input type="checkbox" id={id ? id :"check"} className='peer checked:accent-danger-100 w-5 h-5 cursor-pointer'/>
         <label htmlFor={id ? id :"check"} className={cn('text-base peer-checked:text-danger-100 font-500 transition-colors duration-300 text-white group-hover:text-danger-100  cursor-pointer',lableClass)}>
            {label}
         </label>
    </div>
  )
}

export default BasicCheck